import * as React from "react";
import { useContext } from "react";

import CurrentUserContext from "../../contexts/CurrentUserContext";
import TicketsContext from "../../contexts/TicketsContext";
import Loader from "../helpers/Loader";
import TicketListItem from "./TicketListItem";

interface ITicketListProps {
  queryType: string;
}

const TicketList = (props: ITicketListProps) => {
  const ticketsContext = useContext(TicketsContext);
  const { user, permissions } = useContext(CurrentUserContext);

  if (ticketsContext.isLoading) {
    return <Loader containedLoader={true} />;
  }

  const ticketRenderer = () => {
    if (props.queryType === "Queue") {
      if (ticketsContext.queueTickets.length > 0) {
        return ticketsContext.queueTickets.map((ticket) => {
          if (
            // @ts-ignore
            (permissions &&
              permissions.assigned_orgs &&
              permissions.assigned_orgs.includes(ticket.org_id)) ||
            user.id === ticket.user_id
          ) {
            return (
              <TicketListItem
                key={ticket.id}
                ticket={ticket}
                source="TicketList"
              />
            );
          }
        });
      }
    } else if (props.queryType === "Submitted") {
      if (ticketsContext.submittedTickets.length > 0) {
        return ticketsContext.submittedTickets.map((ticket) => {
          console.log("Submitted Ticket", ticket);

          return (
            <TicketListItem
              key={ticket.id}
              ticket={ticket}
              source="TicketList"
            />
          );
        });
      }
    } else if (props.queryType === "Assigned") {
      if (ticketsContext.assignedTickets.length > 0) {
        return ticketsContext.assignedTickets.map((ticket) => {
          return (
            <TicketListItem
              key={ticket.id}
              ticket={ticket}
              source="TicketList"
            />
          );
        });
      }
    } else if (props.queryType === "Completed") {
      if (ticketsContext.completedTickets.length > 0) {
        return ticketsContext.completedTickets.map((ticket) => {
          return (
            <TicketListItem
              key={ticket.id}
              ticket={ticket}
              source="TicketList"
            />
          );
        });
      }
    } else {
      return null;
    }
  };

  return <div className="sidebar-scroll-container">{ticketRenderer()}</div>;
};

export default TicketList;
