import * as React from "react";
import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

ReactModal.setAppElement(".app-wrapper");

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px"
  }
};

interface IRatingDetailModal {
  handleModalClose: () => void;
  modalIsOpen: boolean;
  ticket: any;
}

const RatingDetailModal = (props: IRatingDetailModal) => {
  const [title, setTitle] = useState(null);
  const [ratingComment, setRatingContent] = useState(null);
  const [rating, setRating] = useState(null);

  const setBaseState = () => {
    setTitle(null);
    setRatingContent(null);
    setRating(null);
  };

  useEffect(() => {
    if (props.ticket) {
      setTitle(props.ticket.title);
      setRatingContent(props.ticket.rating_comment);
      setRating(props.ticket.rating);
    }

    return setBaseState;
  });

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        props.handleModalClose();
      }}
      style={customModalStyles}
    >
      <div className="rating-detail-modal">
        <div className="rating-detail-modal__title">{title}</div>
        <div className="rating-detail-modal__content">{ratingComment}</div>
      </div>
    </ReactModal>
  );
};

export default RatingDetailModal;
