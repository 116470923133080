import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import _ from "lodash";

import DashboardLayout from "../../components/layouts/DashboardLayout";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import SupportTierAssignmentForm from "../../components/support-tiers/SupportTierAssignmentForm";
import SupportTierGroup from "../../components/support-tiers/SupportTierGroup";

const SupportTierDashboard = () => {
  const [tierOneUsers, setTierOneUsers] = useState([]);
  const [tierTwoUsers, setTierTwoUsers] = useState([]);
  const [tierThreeUsers, setTierThreeUsers] = useState([]);

  useEffect(() => {
    getTiers();
  }, []);

  const getTiers = () => {
    ticketingServiceApi
      .get("support_tier_assignments")
      .then((response) => {
        setTierOneUsers(response.data.level_one_users);
        setTierTwoUsers(response.data.level_two_users);
        setTierThreeUsers(response.data.level_three_users);
      })
      .catch((error) => {
        console.log("ERROR GETTING SUPPORT TIERS", error);
      });
  };

  const handleAssignments = (selectedTier: string, assignments: []) => {
    if (selectedTier === "LEVEL_1") {
      setTierOneUsers([...tierOneUsers, ...assignments]);
    } else if (selectedTier === "LEVEL_2") {
      setTierTwoUsers([...tierTwoUsers, ...assignments]);
    } else if (selectedTier === "LEVEL_3") {
      setTierThreeUsers([...tierThreeUsers, ...assignments]);
    }
  };

  const onDragEnd = (result) => {
    const userId = result.draggableId;
    const newTierName = result.destination.droppableId;
    const previousTierName = result.source.droppableId;

    ticketingServiceApi
      .patch(`support_tier_assignments/_`, {
        user_id: userId,
        tier_name: newTierName,
      })
      .then((response) => {
        let user;
        const userIdAsNumber = Number(userId);

        if (previousTierName === "LEVEL_1") {
          user = _.find(tierOneUsers, ["id", userIdAsNumber]);
          const filteredUsers = tierOneUsers.filter(
            (u) => u.id !== userIdAsNumber
          );
          setTierOneUsers(filteredUsers);
        } else if (previousTierName === "LEVEL_2") {
          user = _.find(tierTwoUsers, ["id", userIdAsNumber]);
          const filteredUsers = tierTwoUsers.filter(
            (u) => u.id !== userIdAsNumber
          );
          setTierTwoUsers(filteredUsers);
        } else if (previousTierName === "LEVEL_3") {
          user = _.find(tierThreeUsers, ["id", userIdAsNumber]);
          const filteredUsers = tierThreeUsers.filter(
            (u) => u.id !== userIdAsNumber
          );
          setTierThreeUsers(filteredUsers);
        }

        if (newTierName === "LEVEL_1") {
          setTierOneUsers([...tierOneUsers, user]);
        } else if (newTierName === "LEVEL_2") {
          setTierTwoUsers([...tierTwoUsers, user]);
        } else if (newTierName === "LEVEL_3") {
          setTierThreeUsers([...tierThreeUsers, user]);
        }
      })
      .catch((error) => {
        console.log("ERROR GETTING SUPPORT TIERS", error);
      });
  };

  const handleAssignmentDeletion = (id: number) => {
    const filteredTierOneUsers = tierOneUsers.filter((user) => user.id !== id);
    setTierOneUsers(filteredTierOneUsers);
    const filteredTierTwoUsers = tierTwoUsers.filter((user) => user.id !== id);
    setTierTwoUsers(filteredTierTwoUsers);
    const filteredTierThreeUsers = tierThreeUsers.filter(
      (user) => user.id !== id
    );
    setTierThreeUsers(filteredTierThreeUsers);
  };

  return (
    <DashboardLayout>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="support-tier-grid">
          <div>
            <div className="level-grouping">
              <div className="heading-md level-grouping__title">
                Level 1 Mentors
              </div>
              <SupportTierGroup
                handleAssignmentDeletion={handleAssignmentDeletion}
                users={tierOneUsers}
                tierName="LEVEL_1"
              />
            </div>

            <div className="level-grouping">
              <div className="heading-md level-grouping__title">
                Level 2 Mentors
              </div>
              <SupportTierGroup
                handleAssignmentDeletion={handleAssignmentDeletion}
                users={tierTwoUsers}
                tierName="LEVEL_2"
              />
            </div>

            <div className="level-grouping">
              <div className="heading-md level-grouping__title">
                Level 3 Mentors
              </div>
              <SupportTierGroup
                handleAssignmentDeletion={handleAssignmentDeletion}
                users={tierThreeUsers}
                tierName="LEVEL_3"
              />
            </div>
          </div>

          <SupportTierAssignmentForm handleAssignments={handleAssignments} />
        </div>
      </DragDropContext>
    </DashboardLayout>
  );
};

export default SupportTierDashboard;
