import React from "react";
import { Droppable } from "react-beautiful-dnd";
import SupportTierItem from "./SupportTierItem";

interface ISupportTierGroupProps {
  tierName: string;
  users: any[];
  handleAssignmentDeletion: (number) => void;
}
export default (props: ISupportTierGroupProps) => {
  const handleAssignmentDeletion = (id: number) => {
    props.handleAssignmentDeletion(id);
  };
  const usersRows = () => {
    if (props.users && props.users.length > 0) {
      return props.users.map((user, idx) => {
        if (user.id) {
          return (
            <SupportTierItem
              user={user}
              index={idx}
              key={user.id}
              handleAssignmentDeletion={handleAssignmentDeletion}
            />
          );
        }
      });
    } else {
      return null;
    }
  };

  return (
    <Droppable droppableId={props.tierName}>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          {usersRows()}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
