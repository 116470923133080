import * as React from "react";
import _ from "lodash";
import { ActionCableConsumer } from "react-actioncable-provider";

import AlertContext from "../contexts/AlertContext";
import ticketingServiceApi from "../helpers/ticketingServiceApi";

export default class AlertProvider extends React.Component {
  // alert = {resource_id: "asdf", user_id: '123', alert_type: 'support_chat_room', message: "Some alert message"}
  state = {
    alerts: [],
    addToAlerts: alertsToAdd => {
      const newAlerts = alertsToAdd.map(alertObj => {
        const alertExists = _.find(this.state.alerts, {
          alert_type: alertObj.alert_type,
          resource_id: alertObj.resource_id,
          user_id: alertObj.user_id
        });

        if (!alertExists) {
          return alertObj;
        }
      });

      this.setState({ alerts: newAlerts.concat(this.state.alerts) });
    },
    clearTicketAlerts: () => {
      this.setState({ alerts: [] });
    },
    deleteAlertFromApi: resourceId => {
      const alert = _.find(this.state.alerts, [
        "resource_id",
        resourceId.toString()
      ]);

      if (alert) {
        ticketingServiceApi
          .delete(`alerts/${alert.id}`)
          .then(response => {
            this.removeFromAlertStore(alert.resource_id);
          })
          .catch(error => {
            // TODO
          });
      }
    },
    hasAlert: (resourceId: string, alert_type: string) => {
      const alert = _.find(this.state.alerts, {
        resource_id: resourceId.toString(),
        alert_type: alert_type
      });
      if (alert) {
        return true;
      } else {
        return false;
      }
    },
    hasChatAlerts: () => {
      const chatAlerts = _.find(this.state.alerts, {
        alert_type: "support_chat_room"
      });

      if (chatAlerts) {
        return true;
      } else {
        return false;
      }
    },
    hasTicketAlerts: () => {
      const ticketAlerts = _.find(this.state.alerts, { alert_type: "ticket" });

      if (ticketAlerts) {
        return true;
      } else {
        return false;
      }
    },
    retrieveAlerts: () => {
      this.getAlerts();
    }
  };

  componentDidMount() {
    this.getAlerts();
  }

  getAlerts = () => {
    ticketingServiceApi
      .get("alerts")
      .then(response => {
        this.setState({ alerts: response.data.alerts });
      })
      .catch(error => {
        // TODO
      });
  };

  removeFromAlertStore = (resourceId: string) => {
    const updatedAlerts = this.state.alerts.filter(
      a => a.resource_id !== resourceId.toString()
    );

    this.setState({ alerts: updatedAlerts });
  };

  handleReceivedAlert = alert => {
    this.setState({
      alerts: [...this.state.alerts, alert]
    });
  };

  render() {
    return (
      <AlertContext.Provider value={this.state}>
        <ActionCableConsumer
          channel={{ channel: `AlertsChannel` }}
          onReceived={this.handleReceivedAlert}
        />
        {this.props.children}
      </AlertContext.Provider>
    );
  }
}
