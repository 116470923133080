import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import DashboardLayout from "../../components/layouts/DashboardLayout";
import CurrentUserContext from "../../contexts/CurrentUserContext";

const AdminDashboard = () => {
  const { permissions } = useContext(CurrentUserContext);

  const adminPages = [
    {
      name: "Ratings",
      path: "/ratings",
      icon: <FontAwesomeIcon icon={"star"} />
    },
    {
      name: "Performance",
      path: "/performance",
      icon: <FontAwesomeIcon icon={"medal"} />
    },
    {
      name: "Support Tiers",
      path: "/support-tier-dashboard",
      icon: <FontAwesomeIcon icon={"user-shield"} />
    },
    {
      name: "Escalated Tickets",
      path: "/escalation-dashboard",
      icon: <FontAwesomeIcon icon={"level-up-alt"} />
    },
    {
      name: "Duty Entries",
      path: "/duty-entries-dashboard",
      icon: <FontAwesomeIcon icon={"clock"} />
    }
  ];

  const mentorPages = [
    {
      name: "Ratings",
      path: "/ratings",
      icon: <FontAwesomeIcon icon={"star"} />
    }
  ];

  const cards = () => {
    let pages;
    if (permissions.role === "admin") {
      pages = adminPages;
    } else if (permissions.role === "mentor") {
      pages = mentorPages;
    } else {
      pages = [];
    }

    return pages.map(page => {
      return (
        <Link
          to={page.path}
          key={page.path}
          className="admin-link-cards-wrapper__item"
        >
          {page.icon}
          <div className="admin-link-cards-wrapper__item__title">
            {page.name}
          </div>
        </Link>
      );
    });
  };

  return (
    <DashboardLayout>
      <div className="admin-link-cards-wrapper">{cards()}</div>
    </DashboardLayout>
  );
};

export default AdminDashboard;
