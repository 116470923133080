interface ISelectFieldDataFormatter {
  data: any[];
  labelName: string;
}
export default (args: ISelectFieldDataFormatter) => {
  if (args.data && args.data.length > 0) {
    return args.data.map((el) => ({
      value: el.id,
      label: el[args.labelName],
    }));
  } else {
    return [];
  }
};
