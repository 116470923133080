import * as React from "react";

import DashboardLayout from "../../components/layouts/DashboardLayout";
import TicketsTabsWrapper from "../../components/tabs/TicketsTabsWrapper";
import TicketDetail from "../../components/tickets/TicketDetail";
import ActiveTicketProvider from "../../providers/ActiveTicketProvider";

const TicketDashboard = () => {
  return (
    <DashboardLayout>
      <ActiveTicketProvider>
        <div className="grid-500-1fr">
          <TicketsTabsWrapper />
          <TicketDetail />
        </div>
      </ActiveTicketProvider>
    </DashboardLayout>
  );
};

export default TicketDashboard;
