import * as React from "react";
import { useContext } from "react";

import TicketEscalationsContext from "../../contexts/TicketEscalationsContext";
import Loader from "../helpers/Loader";
import EscalationListItem from "./EscalationListItem";

interface IEscalationListProps {
  escalationType: string;
}
export default (props: IEscalationListProps) => {
  const {
    isLoading,
    pendingEscalations,
    acceptedEscalations,
    archivedEscalations
  } = useContext(TicketEscalationsContext);

  if (isLoading) {
    return <Loader containedLoader={true} />;
  }

  const ticketRenderer = () => {
    if (props.escalationType === "pending") {
      if (pendingEscalations.length > 0) {
        return pendingEscalations.map(escalation => {
          return (
            <EscalationListItem key={escalation.id} escalation={escalation} />
          );
        });
      }
    } else if (props.escalationType === "accepted") {
      if (acceptedEscalations.length > 0) {
        return acceptedEscalations.map(escalation => {
          return (
            <EscalationListItem key={escalation.id} escalation={escalation} />
          );
        });
      }
    } else if (props.escalationType === "archived") {
      if (archivedEscalations.length > 0) {
        return archivedEscalations.map(escalation => {
          return (
            <EscalationListItem key={escalation.id} escalation={escalation} />
          );
        });
      }
    } else {
      return null;
    }
  };

  return <div className="sidebar-scroll-container">{ticketRenderer()}</div>;
};
