import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";

export default () => {
  const [onDuty, setOnDuty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkForActiveDutyEntry();
  }, []);

  const checkForActiveDutyEntry = () => {
    ticketingServiceApi.get("duty_entries/_").then(response => {
      if (response.data.msg === "NO_ACTIVE_DUTY_ENTRIES") {
        setIsLoading(false);
        setOnDuty(false);
      } else if (response.data.duty_entry) {
        setIsLoading(false);
        setOnDuty(true);
      }
    });
  };

  const createDutyEntry = () => {
    ticketingServiceApi.post("duty_entries", {
      duty_entry: {
        start_time: new Date()
      }
    });
  };

  const closeDutyEntry = () => {
    ticketingServiceApi.patch("duty_entries/_", {
      duty_entry: {
        end_time: new Date()
      }
    });
  };

  if (isLoading) {
    return null;
  }

  const handleSwitch = () => {
    setOnDuty(!onDuty);

    if (onDuty) {
      closeDutyEntry();
    } else {
      createDutyEntry();
    }
  };

  return (
    <label>
      <Switch
        onChange={handleSwitch}
        checked={onDuty}
        onColor="#00c274"
        offColor="#333333"
      />
    </label>
  );
};
