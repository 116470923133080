import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";

import CurrentUserContext from "../../contexts/CurrentUserContext";
import NavLinks from "../navigation/NavLinks";
import NavSearch from "../navigation/NavSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import DutyEntryToggle from "../duty-entries/DutyEntryToggle";

interface IDashboardLayoutProps {
  children: any;
}
const DashboardLayout = (props: IDashboardLayoutProps) => {
  const { user, permissions, logoutUser } = useContext(CurrentUserContext);

  const [
    isLoadingPendingEscalations,
    setIsLoadingPendingEscalations
  ] = useState(true);

  const [hasPendingEscalations, setHasPendingEscalations] = useState(false);

  useEffect(() => {
    getPendingEscalations();
  }, []);

  const getPendingEscalations = () => {
    const params = { status: "pending" };
    ticketingServiceApi
      .get("ticket_escalations", { params })
      .then(response => {
        if (
          response.data.ticket_escalations &&
          response.data.ticket_escalations.length > 0
        ) {
          setHasPendingEscalations(true);
        }

        setIsLoadingPendingEscalations(false);
      })
      .catch(error => {
        console.log("Error gettig ticket escalations", error);
      });
  };

  const pendingEscalationsBanner = () => {
    if (user && permissions && permissions.role === "admin") {
      if (isLoadingPendingEscalations || !hasPendingEscalations) {
        return null;
      } else {
        return (
          <div className="top-banner-wrapper">
            <div className="top-banner-wrapper__content">
              <FontAwesomeIcon icon="exclamation-circle" />
              There are pending escalated tickets{" "}
              <Link to="/escalation-dashboard">View</Link>
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <div className="dashboard-layout-wrapper">
      {pendingEscalationsBanner()}
      <div className="navigation-wrapper">
        <NavLinks />
        <NavSearch />

        <div className="right-nav-links">
          {user && permissions && permissions.role === "mentor" ? (
            <DutyEntryToggle />
          ) : null}

          <a onClick={logoutUser} className="sign-out-link">
            <FontAwesomeIcon icon="sign-out-alt" />
          </a>
        </div>
      </div>

      <div className="container">{props.children}</div>
    </div>
  );
};

// @ts-ignore
export default withRouter(DashboardLayout);
