import * as React from "react";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Gravatar from "react-gravatar";

import DashboardLayout from "../../components/layouts/DashboardLayout";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import Loader from "../../components/helpers/Loader";
import RatingDetailModal from "../../components/ratings/RatingDetailModal";

const RatingDashboard = () => {
  const [ratings, setRatings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showRatingDetailModal, setShowRatingDetailModal] = useState(false);
  const [ticketToShowInModal, setTicketToShowInModal] = useState(null);

  useEffect(() => {
    getRatings();
  }, []);

  const getRatings = () => {
    ticketingServiceApi
      .get(`ratings`)
      .then(response => {
        if (response.data.tickets) {
          setRatings(response.data.tickets);
        } else {
          setRatings([]);
        }
        setIsLoading(false);
      })
      .catch(error => {
        // TODO
      });
  };

  const handleModalClose = () => {
    setShowRatingDetailModal(false);
  };

  const handleModalClick = ticket => {
    setTicketToShowInModal(ticket);
    setShowRatingDetailModal(true);
  };

  const mentorIcon = t => {
    const mentor = t.assigned_users.filter(u => !u.ticket_creator)[0];

    if (mentor) {
      return <Gravatar email={mentor.user_email} />;
    } else {
      return <div style={{ width: "50px" }} />;
    }
  };

  const mentorName = t => {
    const mentor = t.assigned_users.filter(u => !u.ticket_creator)[0];

    if (mentor) {
      return (
        <div
          style={{
            fontSize: "1rem",
            textTransform: "uppercase",
            color: "#333333"
          }}
        >
          {mentor.full_name}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const ratingRenderer = () => {
    if (ratings.length > 0) {
      return ratings.map(ticket => {
        return (
          <div
            className="ticket-sidebar-wrapper__items-wrapper__item"
            key={ticket.id}
          >
            <div className="ticket-sidebar-wrapper__items-wrapper__item__left">
              {mentorIcon(ticket)}
              <div className="ticket-sidebar-wrapper__items-wrapper__item__left__title">
                <Link to={`/ticket/${ticket.id}`}>{ticket.title}</Link>
                {mentorName(ticket)}
              </div>
            </div>

            <div className="ticket-sidebar-wrapper__items-wrapper__item__modal-link">
              <div style={{ marginRight: "10px" }}>{ticket.rating} / 10</div>
              {ticket.rating_comment ? (
                <a onClick={() => handleModalClick(ticket)}>
                  <FontAwesomeIcon icon="receipt" />
                </a>
              ) : (
                <div style={{ width: "10px" }} />
              )}
            </div>
          </div>
        );
      });
    } else {
      return (
        <div style={{ fontSize: "2em", textAlign: "center" }}>
          There are currently no ratings
        </div>
      );
    }
  };
  return (
    <DashboardLayout>
      <RatingDetailModal
        modalIsOpen={showRatingDetailModal}
        handleModalClose={handleModalClose}
        ticket={ticketToShowInModal}
      />

      {isLoading ? (
        <Loader containedLoader={true} />
      ) : (
        <div className="centered-flex-wrapper flex-columns">
          <div className="page-heading" style={{ marginBottom: "20px" }}>
            Mentor Reviews
          </div>
          <div className="ticket-sidebar-wrapper" style={{ width: "900px" }}>
            <div className="ticket-sidebar-wrapper__items-wrapper">
              {ratingRenderer()}
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default RatingDashboard;
