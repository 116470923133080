import * as React from "react";
import CommentCard from "./CommentCard";

const commentsRenderer = comments => {
  if (comments && comments.length > 0) {
    return comments.map(comment => (
      <CommentCard
        comment={comment}
        key={comment.id}
        ticketId={comment.ticket_id}
      />
    ));
  } else {
    return null;
  }
};

export default commentsRenderer;
