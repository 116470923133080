const timeCalculator = numberOfSeconds => {
  const positiveSecondCount = Math.abs(numberOfSeconds);

  if (positiveSecondCount < 60) {
    return `${positiveSecondCount} seconds`;
  } else if (positiveSecondCount >= 86400) {
    const formattedDayCount = (positiveSecondCount / 86400).toFixed(1);
    return `${formattedDayCount} days`;
  } else if (positiveSecondCount >= 3600) {
    const formattedDayCount = (positiveSecondCount / 3600).toFixed(1);
    return `${formattedDayCount} hours`;
  } else {
    const formattedMinuteCount = (positiveSecondCount / 60).toFixed(1);
    return `${formattedMinuteCount} minutes`;
  }
};

export default timeCalculator;
