import * as React from "react";
import { createContext } from "react";

interface AppContextInterface {
  logoutUser: () => void;
  loginUser: (user: {}) => void;
  isLoading: boolean;
  user: {
    id?: number | null;
    email?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    org_selected?: string | null;
  };
  loggedInStatus: string;
  permissions: {
    role: string | null;
    level?: string | null;
    assigned_orgs?: any;
  };
}

const CurrentUserContext = createContext<AppContextInterface | null>(null);

export default CurrentUserContext;
