import axios from "axios";

export default axios.create({
  baseURL: process.env.DEVCAMP_API_URL,
  transformRequest: [response => JSON.stringify(response)],
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=UTF-8"
  },
  withCredentials: true
});
