import * as React from "react";
import { useEffect, useContext } from "react";

import DashboardLayout from "../../components/layouts/DashboardLayout";
import ActiveTicketContent from "../../contexts/ActiveTicketContext";
import Loader from "../../components/helpers/Loader";
import TicketDetail from "../../components/tickets/TicketDetail";

const TicketPage = props => {
  const ticketId = props.match.params.id;
  const { getTicket, ticket } = useContext(ActiveTicketContent);

  useEffect(() => {
    if (ticketId) {
      getTicket(ticketId);
    }
  }, [props.match.params.id]);

  return (
    <DashboardLayout>
      {!ticket ? (
        <Loader />
      ) : (
        <div className="ticket-page-wrapper">
          <TicketDetail />
        </div>
      )}
    </DashboardLayout>
  );
};

export default TicketPage;
