import * as React from "react";
import ReactModal from "react-modal";
import Gravatar from "react-gravatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import moment from "moment";
import Truncate from "react-truncate";

import Loader from "../helpers/Loader";

ReactModal.setAppElement(".app-wrapper");

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "80vw",
    height: "80vh"
  }
};

interface ITicketSearchResultModalProps {
  handleModalClose: () => void;
  modalIsOpen: boolean;
  tickets: any[];
  query: string;
  isLoading: boolean;
}

const TicketSearchResultModal = (props: ITicketSearchResultModalProps) => {
  const { modalIsOpen, handleModalClose, query, isLoading, tickets } = props;

  const handleTicketClick = () => {
    handleModalClose();
  };

  const statusRenderer = status => {
    if (status === "pending") {
      return (
        <div
          style={{
            backgroundColor: `#c2d520`,
            color: "black",
            fontWeight: 900,
            fontSize: "1rem",
            textTransform: "uppercase",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingRight: "5px",
            paddingLeft: "5px",
            borderRadius: "3px"
          }}
        >
          <FontAwesomeIcon icon="inbox" />
          <span style={{ marginLeft: "3px" }}>Pending</span>
        </div>
      );
    } else if (status === "in_progress") {
      return (
        <div
          style={{
            backgroundColor: `#333333`,
            color: "white",
            fontWeight: 900,
            fontSize: "1rem",
            textTransform: "uppercase",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingRight: "5px",
            paddingLeft: "5px",
            borderRadius: "3px"
          }}
        >
          <FontAwesomeIcon icon="clock" />
          <span style={{ marginLeft: "3px" }}>In Progress</span>
        </div>
      );
    } else if (status === "resolved") {
      return (
        <div
          style={{
            backgroundColor: `#00c274`,
            color: "white",
            fontWeight: 900,
            fontSize: "1rem",
            textTransform: "uppercase",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingRight: "5px",
            paddingLeft: "5px",
            borderRadius: "3px"
          }}
        >
          <FontAwesomeIcon icon="check-square" />
          <span style={{ marginLeft: "3px" }}>Resolved</span>
        </div>
      );
    } else if (status === "archived") {
      return (
        <div
          style={{
            backgroundColor: `#af2e2e`,
            color: "white",
            fontWeight: 900,
            fontSize: "1rem",
            textTransform: "uppercase",
            paddingTop: "3px",
            paddingBottom: "3px",
            paddingRight: "5px",
            paddingLeft: "5px",
            borderRadius: "3px"
          }}
        >
          <FontAwesomeIcon icon="thumbs-down" />
          <span style={{ marginLeft: "3px" }}>Archived</span>
        </div>
      );
    }
  };

  const ticketsRenderer = () => {
    if (tickets.length > 0) {
      return tickets.map(ticket => {
        return (
          <div key={ticket.id} className="ticket-query-item">
            <div>
              <Gravatar email={ticket.user_email} />
            </div>
            <div
              style={{
                fontWeight: 900,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start"
              }}
            >
              <Link to={`/ticket/${ticket.id}`} onClick={handleTicketClick}>
                <Truncate lines={1} width={600}>
                  {ticket.title}
                </Truncate>
              </Link>

              <div
                style={{ marginTop: "7px", fontWeight: 300, color: "#333333" }}
              >
                <Truncate lines={1} width={600}>
                  {ticket.content}
                </Truncate>
              </div>
            </div>
            <div>
              {moment(ticket.created_at).format("MMMM Do YYYY, h:mm:ss a")}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {statusRenderer(ticket.status)}
            </div>
          </div>
        );
      });
    }
  };

  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        handleModalClose();
      }}
      style={customModalStyles}
    >
      <div className="rating-detail-modal">
        <div className="underlined-header">
          <span style={{ marginRight: "3px" }}>
            <b>Tickets for</b>
          </span>
          "{query}"
        </div>
        {isLoading ? (
          <div style={{ marginTop: "21px" }}>
            <Loader containedLoader={true} />
          </div>
        ) : tickets.length > 0 ? (
          <div className="ticket-query-list">{ticketsRenderer()}</div>
        ) : (
          <div style={{ fontSize: "1.5em", marginTop: "21px" }}>
            It doesn't look like there were any tickets that matched your search
            of <b>{query}</b>
          </div>
        )}
      </div>
    </ReactModal>
  );
};

export default TicketSearchResultModal;
