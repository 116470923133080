import * as React from "react";

import devcampApi from "../helpers/devcampApi";
import ticketingServiceApi from "../helpers/ticketingServiceApi";
import CurrentUserContext from "../contexts/CurrentUserContext";

export default class CurrentUserProvider extends React.Component {
  state = {
    loggedInStatus: "NOT_LOGGED_IN",
    user: {},
    isLoading: true,
    loginUser: (user: {}) => {
      this.setState({
        loggedInStatus: "LOGGED_IN",
        user: user,
      });
    },
    logoutUser: () => {
      this.deleteUserSession();
    },
    toggleIsLoading: () => {
      this.setState({ isLoading: !this.state.isLoading });
    },
    permissions: { role: "" },
  };

  componentDidMount() {
    this.checkLoginStatus();
  }

  deleteUserSession() {
    devcampApi
      .delete("api/log_out", { withCredentials: true })
      .then((response: any) => {
        this.setState({ loggedInStatus: "NOT_LOGGED_IN" });
        window.location.href = "/";
      })
      .catch((error: any) => {
        // TODO
      });
  }

  getUserRole() {
    ticketingServiceApi
      .get(`get-role`, { withCredentials: true })
      .then((response) => {
        this.setState({
          isLoading: false,
          permissions: response.data,
        });
      })
      .catch((error) => {
        console.log("ERROR GETTING SUPPORT TIER", error);
        this.setState({
          isLoading: false,
        });
      });
  }

  checkLoginStatus() {
    devcampApi
      .get(`api/logged_in`, { withCredentials: true })
      .then((response: { data: { status: string; user: any } }) => {
        if (
          response.data.status === "logged_in" &&
          this.state.loggedInStatus === "NOT_LOGGED_IN"
        ) {
          this.setState({
            loggedInStatus: "LOGGED_IN",
            user: response.data.user,
          });
        } else if (
          response.data.status === "not_logged_in" &&
          this.state.loggedInStatus === "LOGGED_IN"
        ) {
          this.setState({
            loggedInStatus: "NOT_LOGGED_IN",
          });
        }

        this.getUserRole();
      })
      .catch((error: any) => {
        // TODO
      });
  }

  render() {
    return (
      <CurrentUserContext.Provider value={this.state}>
        {this.props.children}
      </CurrentUserContext.Provider>
    );
  }
}
