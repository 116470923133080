import * as React from "react";
import { useState, useContext } from "react";
import ReactModal from "react-modal";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CurrentUserContext from "../../contexts/CurrentUserContext";
import ActiveChatContext from "../../contexts/ActiveChatContext";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";

ReactModal.setAppElement(".app-wrapper");

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%"
  }
};

interface INewChatModal {
  handleModalClose: () => void;
  modalIsOpen: boolean;
}

const NewChatModal = (props: INewChatModal) => {
  const currentUserContext = useContext(CurrentUserContext);
  const activeChatContext = useContext(ActiveChatContext);
  const [chatRoomTitle, setChatRoomTitle] = useState("");
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoCompleteUsers, setAutoCompleteUsers] = useState([]);
  const [errorText, setErrorText] = useState(null);

  const setBaseState = () => {
    setChatRoomTitle("");
    setUsersToAdd([]);
    setSearchValue("");
    setAutoCompleteUsers([]);
    setErrorText(null);
  };

  const handleCreateChatRoom = event => {
    event.preventDefault();

    const idsToAdd = usersToAdd
      .map(u => u.value.toString())
      .concat(currentUserContext.user.id.toString());

    let chatRoomToCreate: any = {
      support_chat_room: {
        chat_type: idsToAdd.length > 2 ? "group_room" : "direct_message",
        users: idsToAdd,
        title: chatRoomTitle
      }
    };
    ticketingServiceApi
      .post("support_chat_rooms", chatRoomToCreate)
      .then(response => {
        activeChatContext.setActiveChat(response.data.support_chat_room);
        setBaseState();
        props.handleModalClose();
      })
      .catch(error => {
        setErrorText("An error occurred, please try again");
      });
  };

  const handleChange = value => {
    usersToAdd.filter(user => value.includes(user));
    setUsersToAdd(value);
  };

  const portalPlacement = "bottom";

  const handleQuery = inputValue => {
    setSearchValue(inputValue);

    if (inputValue && inputValue.length > 2) {
      ticketingServiceApi
        .get(`search-users?query=${inputValue}`)
        .then(response => {
          const users = response.data.users.map(user => {
            return {
              label: `${user.first_name} ${user.last_name} ${user.email}`,
              value: user.id
            };
          });

          if (users && users.length > 0) {
            setAutoCompleteUsers(users);
          }
        })
        .catch(error => {
          // TODO
          // show error
        });
    }
  };

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        props.handleModalClose();
      }}
      style={customModalStyles}
    >
      <form onSubmit={handleCreateChatRoom} className="form-wrapper">
        {errorText ? (
          <div className="form-error">
            <FontAwesomeIcon icon="exclamation-circle" />
            {errorText}
          </div>
        ) : null}

        <div className="form-group" style={{ marginBottom: "20px" }}>
          <input
            value={chatRoomTitle}
            onChange={e => setChatRoomTitle(e.target.value)}
            className="text-field"
            required
          />
          <label>Room name</label>
        </div>
        <div className="form-group" style={{ marginBottom: "20px" }}>
          <Select
            isMulti={true}
            inputValue={searchValue}
            onInputChange={val => handleQuery(val)}
            value={usersToAdd}
            onChange={handleChange}
            options={autoCompleteUsers}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999, fontSize: "1.3em" })
            }}
            menuPosition={"fixed"}
            menuPlacement={portalPlacement}
            menuPortalTarget={document.body}
            placeholder="Search via email or name"
            classNamePrefix="search-select"
            isClearable={true}
          />
        </div>

        <div
          className="login-btn-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <button className="btn-primary" type="submit">
            Create chatroom
          </button>
        </div>
      </form>
    </ReactModal>
  );
};

export default NewChatModal;
