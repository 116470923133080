import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import TicketSearchResultModal from "../tickets/TicketSearchResultModal";

const NavSearch = () => {
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleSubmit = evt => {
    if (!query) {
      alert("Please search for a keyword or phrase");
    } else {
      setModalIsOpen(true);
      setIsLoading(true);
      ticketingServiceApi
        .get(`ticket_query?query=${query}`)
        .then(response => {
          setTickets(response.data.tickets);
          setIsLoading(false);
        })
        .catch(error => {
          // TODO
          // show error
        });
    }
    evt.preventDefault();
  };

  const handleModalClose = () => {
    setQuery("");
    setTickets([]);
    setModalIsOpen(false);
  };

  return (
    <div className="navigation-search-form-wrapper">
      <TicketSearchResultModal
        isLoading={isLoading}
        tickets={tickets}
        modalIsOpen={modalIsOpen}
        query={query}
        handleModalClose={handleModalClose}
      />
      <form onSubmit={handleSubmit}>
        <div className="input-with-attached-icon">
          <input
            type="text"
            onChange={e => setQuery(e.target.value)}
            value={query}
          />

          <button type="submit">
            <FontAwesomeIcon icon="search" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default NavSearch;
