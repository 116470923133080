import * as React from "react";
import ReactDOM from "react-dom";
import { ActionCableProvider } from "react-actioncable-provider";

import AlertProvider from "./providers/AlertProvider";
import CurrentUserProvider from "./providers/CurrentUserProvider";
import CurrentUserContext from "./contexts/CurrentUserContext";

import App from "./components/app";
import "./style/main.scss";
import Loader from "./components/helpers/Loader";
import Icons from "./components/helpers/icons";
import ActiveTicketProvider from "./providers/ActiveTicketProvider";
import ActiveChatProvider from "./providers/ActiveChatProvider";
import TicketsProvider from "./providers/TicketsProvider";

function main() {
  Icons();
  ReactDOM.render(
    <ActionCableProvider url={process.env.ACTION_CABLE_URL}>
      <CurrentUserProvider>
        <TicketsProvider>
          <AlertProvider>
            <ActiveTicketProvider>
              <ActiveChatProvider>
                <CurrentUserContext.Consumer>
                  {(context: { isLoading: any }) =>
                    context.isLoading ? <Loader /> : <App />
                  }
                </CurrentUserContext.Consumer>
              </ActiveChatProvider>
            </ActiveTicketProvider>
          </AlertProvider>
        </TicketsProvider>
      </CurrentUserProvider>
    </ActionCableProvider>,
    document.querySelector(".app-wrapper")
  );
}

document.addEventListener("DOMContentLoaded", main);
