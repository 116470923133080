import React from "react";

import DashboardLayout from "../../components/layouts/DashboardLayout";
import TicketEscalationsProvider from "../../providers/TicketEscalationsProvider";
import EscalationDashboardTabsWrapper from "../../components/tabs/EscalationDashboardTabsWrapper";
import TicketDetail from "../../components/tickets/TicketDetail";

export default () => {
  return (
    <TicketEscalationsProvider>
      <DashboardLayout>
        <div className="grid-500-1fr">
          <EscalationDashboardTabsWrapper />
          <TicketDetail />
        </div>
      </DashboardLayout>
    </TicketEscalationsProvider>
  );
};
