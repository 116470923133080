import * as React from "react";
import { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ActiveChatContext from "../../contexts/ActiveChatContext";

export default () => {
  const activeChatContext = useContext(ActiveChatContext);
  const [content, setContent] = useState("");

  const submitMessage = (event) => {
    event.preventDefault();
    activeChatContext.submitMessage(content);
    setContent("");
  };

  return (
    <form className="chat-form" onSubmit={submitMessage}>
      <div className="input-with-attached-icon">
        <input
          type="text"
          onChange={(evt) => setContent(evt.target.value)}
          value={content}
          required
        />

        <button style={{ marginLeft: "0px" }}>
          <FontAwesomeIcon icon="comments" />
        </button>
      </div>
    </form>
  );
};
