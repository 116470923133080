import * as React from "react";
import { useState, useContext } from "react";
import SweetAlert from "sweetalert-react";

import TicketsContext from "../contexts/TicketsContext";
import ticketingServiceApi from "../helpers/ticketingServiceApi";
import ActiveTicketContext from "../contexts/ActiveTicketContext";

const ActiveTicketProvider = props => {
  const emptyTicket: any = {};
  const [ticket, setTicket] = useState(emptyTicket);
  const [ticketAlreadyAssigned, setTicketAlreadyAssigned] = useState(false);
  const ticketsContext = useContext(TicketsContext);

  const setActiveTicket = ticket => {
    getTicket(ticket.id);
  };

  const getTicket = ticketId => {
    ticketingServiceApi
      .get(`tickets/${ticketId}`)
      .then(response => {
        console.log("RESPONSE FROM ACTIVE TICKET", response.data);

        setTicket(response.data.ticket);
        ticketsContext.setActiveTicket(response.data.ticket);
      })
      .catch(error => {
        // TODO
      });
  };

  const clearActiveTicket = () => {
    setTicket({});
  };

  const assignTicketToUser = (user: {}) => {
    createTicketAssignmentOnApi(user);
  };

  const markTicketResolved = () => {
    updateTicketStatusOnApi("RESOLVED");
  };

  const markTicketArchived = () => {
    updateTicketStatusOnApi("ARCHIVED");
  };

  const updateTicketStatusOnApi = (status: string) => {
    let apiEndpoint;

    if (status === "RESOLVED") {
      apiEndpoint = "completed_tickets";
    } else if (status === "ARCHIVED") {
      apiEndpoint = "archived_tickets";
    }

    ticketingServiceApi
      .patch(`${apiEndpoint}/${ticket.id}`, {
        ticket_status: status
      })
      .then(response => {
        window.location.href = `/tickets`;
      })
      .catch(error => {
        // TODO
      });
  };

  const createTicketAssignmentOnApi = (user: any) => {
    ticketingServiceApi
      .post("ticket_assignments", {
        ticket_id: ticket.id
      })
      .then(response => {
        if (response.data.msg === "TICKET_ALREADY_ASSIGNED") {
          setTicketAlreadyAssigned(true);
        } else {
          const receivedTicket = response.data.ticket;
          setTicket(receivedTicket);
          window.location.href = `/ticket/${receivedTicket.id}`;
        }
      })
      .catch(error => {
        // TODO
      });
  };

  const stateValues = {
    ticket,
    setActiveTicket,
    clearActiveTicket,
    assignTicketToUser,
    markTicketResolved,
    markTicketArchived,
    getTicket
  };

  return (
    <ActiveTicketContext.Provider value={stateValues}>
      <SweetAlert
        show={ticketAlreadyAssigned}
        title="Assignment Alert"
        text={"This ticket has already been assigned"}
        onConfirm={() => setTicketAlreadyAssigned(false)}
        onEscapeKey={() => setTicketAlreadyAssigned(false)}
        onOutsideClick={() => setTicketAlreadyAssigned(false)}
        confirmButtonColor="#00c274"
      />
      {props.children}
    </ActiveTicketContext.Provider>
  );
};

export default ActiveTicketProvider;
