import * as React from "react";
import { ActionCableConsumer } from "react-actioncable-provider";
import _ from "lodash";

import ticketingServiceApi from "../helpers/ticketingServiceApi";
import TicketsContext from "../contexts/TicketsContext";

export default class TicketsProvider extends React.Component {
  state = {
    isLoading: true,
    queueTickets: [],
    submittedTickets: [],
    assignedTickets: [],
    completedTickets: [],
    activeTicketId: null,
    updatingCommentsForActiveTicket: [],
    setActiveTicket: (ticket) => {
      this.getTicket(ticket.id);
    },
    removeComment: (commentId) => {
      this.setState({
        updatingCommentsForActiveTicket: this.state.updatingCommentsForActiveTicket.filter(
          (comment) => {
            return commentId !== comment.id;
          }
        ),
      });
    },
  };

  getTicket = (ticketId) => {
    ticketingServiceApi
      .get(`tickets/${ticketId}`)
      .then((response) => {
        this.connectActiveTicketChannel(response.data.ticket.id);
        this.prePopulateCommentsForActiveTickets(response.data.ticket.comments);
      })
      .catch((error) => {
        // TODO
      });
  };

  componentDidMount() {
    this.getTickets("tickets");
    this.getTickets("scoped_tickets");
    this.getTickets("ticket_assignments");
    this.getTickets("completed_tickets");
    this.setState({ isLoading: false });
  }

  prePopulateCommentsForActiveTickets = (comments) => {
    this.setState({ updatingCommentsForActiveTicket: comments });
  };

  getTickets = (endpoint: string) => {
    ticketingServiceApi
      .get(endpoint)
      .then((response) => {
        if (response.data.tickets && response.data.tickets.length > 0) {
          const sortedTickets = response.data.tickets.sort(function compare(
            a,
            b
          ) {
            const dateA: any = new Date(a.updated_at);
            const dateB: any = new Date(b.updated_at);
            return dateB - dateA;
          });

          if (endpoint === "tickets") {
            this.setState({ queueTickets: sortedTickets });
          } else if (endpoint === "scoped_tickets") {
            this.setState({ submittedTickets: sortedTickets });
          } else if (endpoint === "ticket_assignments") {
            this.setState({ assignedTickets: sortedTickets });
          } else if (endpoint === "completed_tickets") {
            this.setState({ completedTickets: sortedTickets });
          }
        }
      })
      .catch((error) => {
        // TODO
      });
  };

  handleReceivedQueueTicket = (ticket) => {
    ticket.comments = [];
    const selfAssignedUser = {
      user_email: ticket.user_email,
      user_id: ticket.user_id,
      full_name: ticket.full_name,
    };
    ticket.assigned_users = [selfAssignedUser];

    const combinedTickets = [ticket, ...this.state.queueTickets];
    this.setState({
      queueTickets: _.uniqBy(combinedTickets, "id"),
    });
  };

  handleReceivedAssignedTicket = (response) => {
    const { ticket, assignment } = response;

    const filteredQueueTickets = this.state.queueTickets.filter(
      (queueTicket) => {
        return ticket.id !== queueTicket.id;
      }
    );

    this.setState({ queueTickets: filteredQueueTickets });
  };

  handleReceivedCompletedTicket = (response) => {
    const { ticket, assignment } = response;

    const filteredSubmittedTickets = this.state.submittedTickets.filter(
      (submittedTicket) => {
        return ticket.id !== submittedTicket.id;
      }
    );

    this.setState({
      submittedTickets: filteredSubmittedTickets,
      completedTickets: [ticket, ...this.state.completedTickets],
    });
  };

  handleReceivedEscalatedTicket = (receivedTicket) => {
    const { id } = receivedTicket;

    const filteredQueueTickets = this.state.queueTickets.filter((ticket) => {
      return id !== ticket.id;
    });

    const filteredSubmittedTickets = this.state.submittedTickets.filter(
      (ticket) => {
        return id !== ticket.id;
      }
    );

    const filteredAssignedTickets = this.state.assignedTickets.filter(
      (ticket) => {
        return id !== ticket.id;
      }
    );

    const filteredCompletedTickets = this.state.completedTickets.filter(
      (ticket) => {
        return id !== ticket.id;
      }
    );

    this.setState({ queueTickets: filteredQueueTickets });
    this.setState({ submittedTickets: filteredSubmittedTickets });
    this.setState({ assignedTickets: filteredAssignedTickets });
    this.setState({ completedTickets: filteredCompletedTickets });
  };

  handleReceivedComment = (comment) => {
    if (
      this.state.updatingCommentsForActiveTicket &&
      this.state.updatingCommentsForActiveTicket.length > 0
    ) {
      const existingComment = _.find(
        this.state.updatingCommentsForActiveTicket,
        ["id", comment.id]
      );

      if (existingComment) {
        let comments = this.state.updatingCommentsForActiveTicket;
        const currentIdx = _.findIndex(comments, ["id", existingComment.id]);
        comments[currentIdx] = comment;

        this.setState({ updatingCommentsForActiveTicket: comments });
      } else {
        this.setState({
          updatingCommentsForActiveTicket: [
            ...this.state.updatingCommentsForActiveTicket,
            comment,
          ],
        });
      }
    } else {
      this.setState({
        updatingCommentsForActiveTicket: [comment],
      });
    }
  };

  connectActiveTicketChannel = (ticketId) => {
    if (ticketId) {
      this.setState({ activeTicketId: ticketId });
    }
  };

  render() {
    return (
      <TicketsContext.Provider value={this.state}>
        {this.state.activeTicketId ? (
          <ActionCableConsumer
            channel={{
              channel: `TicketCommentChannel`,
              ticket_id: this.state.activeTicketId,
            }}
            onReceived={this.handleReceivedComment}
          />
        ) : null}
        <ActionCableConsumer
          channel={{ channel: `QueueTicketsChannel` }}
          onReceived={this.handleReceivedQueueTicket}
        />
        <ActionCableConsumer
          channel={{ channel: `AssignedTicketsChannel` }}
          onReceived={this.handleReceivedAssignedTicket}
        />
        <ActionCableConsumer
          channel={{ channel: `CompletedTicketsChannel` }}
          onReceived={this.handleReceivedCompletedTicket}
        />
        <ActionCableConsumer
          channel={{ channel: `EscalatedTicketChannel` }}
          onReceived={this.handleReceivedEscalatedTicket}
        />
        {this.props.children}
      </TicketsContext.Provider>
    );
  }
}
