import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ILoaderProps {
  containedLoader?: boolean;
}

const Loader = (props: ILoaderProps) => {
  const fullPageStyles = {
    height: "100vh",
    width: "100vw"
  };

  const containedStyles = {
    height: "100%",
    width: "100%"
  };

  return (
    <div
      className="loader"
      style={props.containedLoader ? containedStyles : fullPageStyles}
    >
      <FontAwesomeIcon icon="spinner" spin />
    </div>
  );
};

export default Loader;
