import _ from "lodash";

export const formatErrors = errorList => {
  const errorContent = Object.keys(errorList).map(key => ({
    key,
    value: errorList[key]
  }));

  const formattedErrors: Array<any> = errorContent.map(name => {
    return `${_.upperFirst(name.key)}: ${name.value.join(", ")}`;
  });

  return formattedErrors.join(", ");
};
