import * as React from "react";

import ticketingServiceApi from "../helpers/ticketingServiceApi";
import TicketEscalationsContext from "../contexts/TicketEscalationsContext";

export default class TicketEscalationsProvider extends React.Component {
  state = {
    isLoading: true,
    pendingEscalations: [],
    acceptedEscalations: [],
    archivedEscalations: []
  };

  componentDidMount() {
    this.getEscalations("pending");
    this.getEscalations("accepted");
    this.getEscalations("archived");
    this.setState({ isLoading: false });
  }

  getEscalations = (status: string) => {
    ticketingServiceApi
      .get(`ticket_escalations?status=${status}`)
      .then(response => {
        const ticketEscalations = response.data.ticket_escalations;

        if (ticketEscalations && ticketEscalations.length > 0) {
          if (status === "pending") {
            this.setState({
              pendingEscalations: ticketEscalations
            });
          } else if (status === "accepted") {
            this.setState({
              acceptedEscalations: ticketEscalations
            });
          } else if (status === "archived") {
            this.setState({
              archivedEscalations: ticketEscalations
            });
          }
        }
      })
      .catch(error => {
        // TODO
      });
  };

  render() {
    return (
      <TicketEscalationsContext.Provider value={this.state}>
        {this.props.children}
      </TicketEscalationsContext.Provider>
    );
  }
}
