import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Gravatar from "react-gravatar";
import Truncate from "react-truncate";
import moment from "moment";
import "moment-duration-format";
import _ from "lodash";

import timeCalculator from "../../helpers/timeCalculator";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import Loader from "../../components/helpers/Loader";

export default class UserDetail extends React.Component {
  state = {
    user: null,
    permissions: null,
    userIsLoading: true,
    tickets: [],
    totalCount: 0,
    hasMore: true,
    currentPage: 0,
    ticketActivitiesAreLoading: true,
    infiniteScrollIsLoading: true,
    errorGettingTicketActivities: false,
    statsLoading: true,
    mentorTicketCount: null,
    mentorAverageRating: null,
    mentorAverageResponseTimeInSeconds: null,
    studentTicketCount: null,
    studentAverageRating: null,
    studentAverageResponseTimeInSeconds: null
  };

  setBaseState = () => {
    this.setState({
      user: null,
      userIsLoading: true,
      tickets: [],
      totalCount: 0,
      hasMore: true,
      currentPage: 0,
      ticketActivitiesAreLoading: true,
      infiniteScrollIsLoading: true,
      errorGettingTicketActivities: false,
      statsLoading: true,
      mentorTicketCount: null,
      mentorAverageRating: null,
      mentorAverageResponseTimeInSeconds: null,
      studentTicketCount: null,
      studentAverageRating: null,
      studentAverageResponseTimeInSeconds: null,
      permissions: null
    });
  };

  componentDidMount = () => {
    this.getUser();
  };

  componentDidUpdate = () => {
    this.activateInfiniteScroll();
  };

  componentWillUnmount = () => {
    this.setBaseState();
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    const props: any = this.props;

    if (nextProps.match.params.id !== props.match.params.id) {
      this.setBaseState();
      this.getUser();
      return true;
    } else if (
      !this.state.user ||
      this.state.userIsLoading ||
      this.state.ticketActivitiesAreLoading ||
      this.state.infiniteScrollIsLoading ||
      this.state.statsLoading
    ) {
      return true;
    } else {
      return false;
    }
  };

  activateInfiniteScroll = () => {
    const {
      errorGettingTicketActivities,
      infiniteScrollIsLoading,
      tickets,
      totalCount,
      hasMore
    } = this.state;

    window.onscroll = () => {
      if (
        errorGettingTicketActivities ||
        infiniteScrollIsLoading ||
        tickets.length === totalCount ||
        !hasMore
      ) {
        return;
      }

      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        this.getTicketActivities(this.state.permissions.role);
      }
    };
  };

  getUser = () => {
    const props: any = this.props;
    const userId = props.match.params.id;

    ticketingServiceApi
      .get(`user_by_id/${userId}`)
      .then(response => {
        console.log("RESPONSE FOR GETTING USER", response.data);

        if (
          response.data.permissions.role === "mentor" ||
          response.data.permissions.role === "admin"
        ) {
          this.getMentorStats();
        } else {
          this.getStudentStats();
        }

        this.getTicketActivities(response.data.permissions.role);

        this.setState({
          user: response.data.user,
          userIsLoading: false,
          permissions: response.data.permissions
        });
      })
      .catch(error => {
        // TODO
      });
  };

  getMentorStats = () => {
    const props: any = this.props;
    const userId = props.match.params.id;

    ticketingServiceApi
      .get(`mentor_stats/${userId}`)
      .then(response => {
        this.setState({
          mentorTicketCount: response.data.total_tickets,
          mentorAverageResponseTimeInSeconds:
            response.data.average_response_time,
          mentorAverageRating: response.data.average_rating,
          statsLoading: false
        });
      })
      .catch(error => {
        // TODO
      });
  };

  getStudentStats = () => {
    const props: any = this.props;
    const userId = props.match.params.id;

    ticketingServiceApi
      .get(`student_stats/${userId}`)
      .then(response => {
        this.setState({
          studentTicketCount: response.data.total_tickets,
          studentAverageResponseTimeInSeconds:
            response.data.average_response_time,
          studentAverageRating: response.data.average_rating,
          statsLoading: false
        });
      })
      .catch(error => {
        // TODO
      });
  };

  getTicketActivities = role => {
    const props: any = this.props;
    const userId = props.match.params.id;

    this.setState({
      currentPage: this.state.currentPage + 1
    });

    if (this.state.currentPage >= 1) {
      this.setState({
        infiniteScrollIsLoading: true
      });
    }

    let userType;

    if (role === "admin" || role === "mentor") {
      userType = "mentor";
    } else {
      userType = "student";
    }

    ticketingServiceApi
      .get(
        `ticket_activities_by_${userType}/${userId}?page=${this.state.currentPage}`
      )
      .then(response => {
        const uniqueTickets = _.uniqBy(
          this.state.tickets.concat([...response.data.tickets]),
          "id"
        );

        if (response.data.tickets.length === 0) {
          this.setState({ hasMore: false });
        }

        this.setState({
          tickets: uniqueTickets,
          infiniteScrollIsLoading: false,
          ticketActivitiesAreLoading: false,
          totalCount: response.data.meta.total_records
        });
      })
      .catch(error => {
        // TODO
      });
  };

  activityRenderer = (ticket, status) => {
    if (ticket.ticket_activities && ticket.ticket_activities.length > 0) {
      const date = _.find(ticket.ticket_activities, ["status", status]);

      if (date) {
        const ticketDate = moment(ticket.created_at);
        const activityDate = moment(date.created_at);
        const differenceInSeconds = ticketDate.diff(activityDate, "seconds");
        return timeCalculator(differenceInSeconds);
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  studentIcon = t => {
    const student = t.assigned_users.filter(u => u.ticket_creator)[0];

    if (student) {
      return <Gravatar email={student.user_email} />;
    } else {
      return <div style={{ width: "100%" }} />;
    }
  };

  mentorIcon = t => {
    const mentor = t.assigned_users.filter(u => !u.ticket_creator)[0];

    if (mentor) {
      return <Gravatar email={mentor.user_email} />;
    } else {
      return <div style={{ width: "100%" }} />;
    }
  };

  mentorName = t => {
    const mentor = t.assigned_users.filter(u => !u.ticket_creator)[0];

    if (mentor) {
      return (
        <div
          style={{
            textTransform: "uppercase",
            color: "#333333"
          }}
        >
          {mentor.full_name}
        </div>
      );
    } else {
      return <div />;
    }
  };

  studentName = t => {
    const student = t.assigned_users.filter(u => u.ticket_creator)[0];

    if (student) {
      return (
        <div
          style={{
            textTransform: "uppercase",
            color: "#333333"
          }}
        >
          {student.full_name}
        </div>
      );
    } else {
      return <div />;
    }
  };

  mentorId = t => {
    const mentor = t.assigned_users.filter(u => !u.ticket_creator)[0];

    if (mentor) {
      return mentor.user_id;
    } else {
      return null;
    }
  };

  studentId = t => {
    const student = t.assigned_users.filter(u => u.ticket_creator)[0];

    if (student) {
      return student.user_id;
    } else {
      return null;
    }
  };

  ticketActivitiesRenderer = () => {
    if (this.state.tickets.length > 0) {
      const {
        mentorId,
        mentorIcon,
        mentorName,
        studentId,
        studentIcon,
        studentName,
        activityRenderer
      } = this;

      return this.state.tickets.map(ticket => {
        return (
          <tr key={ticket.id}>
            <td>
              {mentorId(ticket) ? (
                <a href={`/u/${mentorId(ticket)}`}>{mentorIcon(ticket)}</a>
              ) : (
                mentorIcon(ticket)
              )}
            </td>
            <td>
              {mentorId(ticket) ? (
                <a href={`/u/${mentorId(ticket)}`}>{mentorName(ticket)}</a>
              ) : (
                mentorName(ticket)
              )}
            </td>
            <td>
              {studentId(ticket) ? (
                <a href={`/u/${studentId(ticket)}`}>{studentIcon(ticket)}</a>
              ) : (
                studentIcon(ticket)
              )}
            </td>
            <td>
              {studentId(ticket) ? (
                <a href={`/u/${studentId(ticket)}`}>{studentName(ticket)}</a>
              ) : (
                studentName(ticket)
              )}
            </td>
            <td>
              <a href={`/ticket/${ticket.id}`}>
                <Truncate lines={1} width={300}>
                  {ticket.title}
                </Truncate>
              </a>
            </td>

            <td>{moment(ticket.created_at).format("MM-D-YY h:mm:ss a")}</td>

            <td>{activityRenderer(ticket, "first_comment")}</td>
            <td>{activityRenderer(ticket, "in_progress")}</td>
            <td>{activityRenderer(ticket, "resolved")}</td>
            <td>
              {ticket.rating ? (
                <div style={{ marginRight: "10px" }}>{ticket.rating} / 10</div>
              ) : (
                <div>N/A</div>
              )}
            </td>
            <td>{ticket.view_count}</td>
          </tr>
        );
      });
    } else {
      return (
        <div style={{ fontSize: "2em", textAlign: "center" }}>
          There is currently no performance data
        </div>
      );
    }
  };

  secondsToFormattedMinutes = seconds => {
    if (seconds) {
      const duration: any = moment.duration(seconds, "seconds");
      return duration.format("hh:mm:ss");
    }
  };

  statRenderer = () => {
    const {
      statsLoading,
      user,
      mentorTicketCount,
      mentorAverageResponseTimeInSeconds,
      mentorAverageRating,
      studentTicketCount,
      studentAverageResponseTimeInSeconds,
      studentAverageRating
    } = this.state;
    const { secondsToFormattedMinutes } = this;

    if (statsLoading) {
      return <Loader containedLoader={true} />;
    } else if (user) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div className="data-box">
            <div className="data-box__heading">
              {mentorTicketCount || studentTicketCount}
            </div>
            <div className="data-box__subheading">TICKETS</div>
          </div>
          <div className="data-box">
            <div className="data-box__heading">
              {secondsToFormattedMinutes(
                mentorAverageResponseTimeInSeconds ||
                  studentAverageResponseTimeInSeconds
              )}
            </div>
            <div className="data-box__subheading">AVG. RESPONSE TIME</div>
          </div>
          <div className="data-box">
            <div className="data-box__heading">
              {mentorAverageRating
                ? parseFloat(mentorAverageRating).toFixed(1)
                : null || studentAverageRating
                ? parseFloat(studentAverageRating).toFixed(1)
                : null}
            </div>
            <div className="data-box__subheading">AVG. RATING</div>
          </div>
        </div>
      );
    }
  };

  userRenderer = () => {
    const { userIsLoading, user } = this.state;
    const { statRenderer } = this;

    if (userIsLoading) {
      return <Loader containedLoader={true} />;
    } else {
      return (
        <div className="user-detail-hero-box">
          <div
            className="page-heading"
            style={{ marginBottom: "20px", color: "white" }}
          >
            {`${user.first_name} ${user.last_name}`}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "50px 1fr 1fr",
              gridGap: "50px"
            }}
          >
            <div>
              <Gravatar email={user.email} />
            </div>

            <div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "21px 1fr",
                  gridGap: "10px",
                  alignItems: "center",
                  marginBottom: "15px"
                }}
              >
                <div style={{ color: "white" }}>
                  <FontAwesomeIcon icon="envelope" size="2x" />
                </div>
                <div
                  style={{ fontSize: "1.5em", fontWeight: 900, color: "white" }}
                >
                  {user.email}
                </div>
              </div>

              {user.github_username ? (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "21px 1fr",
                    gridGap: "10px",
                    alignItems: "center",
                    marginBottom: "15px"
                  }}
                >
                  <div style={{ color: "white" }}>
                    <FontAwesomeIcon icon="code" size="2x" />
                  </div>
                  <div
                    style={{
                      fontSize: "1.5em",
                      fontWeight: 900,
                      color: "white"
                    }}
                  >
                    <a
                      href={`https://github.com/${user.github_username}`}
                      target="_blank"
                      style={{ color: "white" }}
                    >
                      {user.github_username}
                    </a>
                  </div>
                </div>
              ) : null}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {statRenderer()}
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    const { ticketActivitiesAreLoading, infiniteScrollIsLoading } = this.state;
    const { userRenderer, ticketActivitiesRenderer } = this;

    return (
      <DashboardLayout>
        {userRenderer()}
        {ticketActivitiesAreLoading ? (
          <Loader containedLoader={true} />
        ) : (
          <div>
            <div className="page-heading" style={{ marginBottom: "20px" }}>
              Ticket Performance
            </div>
            <div>
              <table style={{ width: "98%" }}>
                <thead>
                  <tr>
                    <th colSpan={2}>MENTOR</th>
                    <th colSpan={2}>STUDENT</th>
                    <th>TICKET</th>
                    <th>CREATED</th>
                    <th>1ST COMMENT</th>
                    <th>ASSIGNED</th>
                    <th>RESOLVED</th>
                    <th>RATING</th>
                    <th>VIEWS</th>
                  </tr>
                </thead>
                <tbody>{ticketActivitiesRenderer()}</tbody>
              </table>
              {infiniteScrollIsLoading ? (
                <Loader containedLoader={true} />
              ) : null}
            </div>
          </div>
        )}
      </DashboardLayout>
    );
  }
}
