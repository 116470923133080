import * as React from "react";
import {
  useRef,
  useState,
  useContext,
  useLayoutEffect,
  useEffect
} from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { useRect } from "@reach/rect";
import Glyph from "../../charts/Glyph";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import Loader from "../helpers/Loader";

const MentorDashboardTabsWrapper = () => {
  const AnimatedContext = React.createContext(null);
  const currentUserContext = useContext(CurrentUserContext);
  const [isLoadingTicketData, setIsLoadingTicketData] = useState(true);
  const [ticketData, setTicketData] = useState([]);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    return function cleanup() {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  });

  useEffect(() => {
    getTicketData();
  }, []);

  const getTicketData = () => {
    ticketingServiceApi
      .get("graph-data/ticket-activity")
      .then(response => {
        const formattedData = [];

        response.data.forEach(dateObj => {
          formattedData.push({
            date: new Date(dateObj.date),
            value: dateObj.value
          });
        });

        setTicketData(formattedData);

        setIsLoadingTicketData(false);
      })
      .catch(error => {
        // TODO
        // show error
      });
  };

  const [windowWidth, setWindowWidth] = useState(0);

  const updateWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
  };

  function AnimatedTabs(props) {
    // need to store the position of the selected Tab so we can
    // animate the bar to its position
    const [selectedRect, setSelectedRect] = useState(null);

    // need to measure the parent element so we can measure
    // the relative "left" for the bar
    const tabsRef = useRef();
    const tabsRect = useRect(tabsRef);

    // Put the function to change the positions on context so the
    // Tabs down the tree can easily access it
    return (
      <AnimatedContext.Provider value={setSelectedRect}>
        <Tabs
          {...props}
          ref={tabsRef}
          style={{ ...props.style, position: "relative" }}
        >
          {props.children[0]}

          {/* put the bar inbetween the TabList and TabPanels */}
          <div
            style={{
              position: "absolute",
              height: 2,
              background: "#00c274",
              marginTop: -2,

              // Here is the actual animation part, we use the
              // rect from the selected tab to set the styles of the bar
              transition: "all 300ms ease",
              left: selectedRect && selectedRect.left - (tabsRect ? tabsRect.left : 0),
              width: selectedRect && selectedRect.width
            }}
          />

          {props.children[1]}
        </Tabs>
      </AnimatedContext.Provider>
    );
  }

  function AnimatedTab(props) {
    const { isSelected } = props;

    // Each tab measures itself
    const ref = useRef();
    const rect = useRect(ref, isSelected);

    // and calls up to the parent when it becomes selected
    // we useLayoutEffect to avoid flicker
    const setSelectedRect = useContext(AnimatedContext);
    useLayoutEffect(() => {
      if (isSelected) setSelectedRect(rect);
    }, [isSelected, rect, setSelectedRect]);

    return (
      <Tab
        ref={ref}
        {...props}
        style={{
          ...props.style,
          border: "none",
          backgroundColor: "transparent",
          fontSize: "1.5rem",
          fontFamily: "Ubuntu",
          outline: "none",
          fontWeight: "900",
          textTransform: "uppercase",
          color: "#00c274",
          cursor: "pointer"
        }}
      />
    );
  }

  return (
    <AnimatedTabs color="#00c274">
      <TabList style={{ justifyContent: "space-around" }}>
        <AnimatedTab style={{ flex: 1, padding: 21 }}>Tickets</AnimatedTab>
      </TabList>
      <TabPanels style={{ paddingTop: 20 }}>
        <TabPanel style={{ outline: "none" }}>
          {isLoadingTicketData ? (
            <Loader />
          ) : (
              <Glyph
                data={ticketData}
                height={500}
                width={windowWidth - 100}
                margin={{
                  right: 100,
                  left: 100,
                  top: 100,
                  bottom: 100
                }}
              />
            )}
        </TabPanel>
      </TabPanels>
    </AnimatedTabs>
  );
};

export default MentorDashboardTabsWrapper;
