import * as React from "react";
import { useState, useEffect } from "react";
import ReactModal from "react-modal";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import Loader from "../helpers/Loader";

ReactModal.setAppElement(".app-wrapper");

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px",
  },
};

interface ISupportTierOrganizationAssignmentsModal {
  handleModalClose: () => void;
  modalIsOpen: boolean;
  userId: number;
}

interface IAssignedOrganizations {
  id: number;
  name: string;
  org_logo_url?: string;
  subdomain: string;
}

export default (props: ISupportTierOrganizationAssignmentsModal) => {
  const [errorText, setErrorText] = useState(null);
  const [assignmentsAreLoading, setAssignmentsAreLoading] = useState(true);
  const [currentAssignments, setCurrentAssignments] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsAreLoading, setOrganizationsAreLoading] = useState(true);

  useEffect(() => {
    if (props.modalIsOpen) {
      getOrgAssignments(props.userId);
    }
  }, [props.modalIsOpen]);

  const getOrgAssignments = (userId: number) => {
    ticketingServiceApi
      .get(`support_tier_organization_assignments/${userId}`)
      .then((response: any) => {
        const assignedOrganizations: IAssignedOrganizations[] =
          response.data.assigned_organizations;
        setCurrentAssignments(assignedOrganizations);
        setAssignmentsAreLoading(false);

        if (assignedOrganizations) {
          const orgIds = assignedOrganizations.map((org) => org.id);

          getOrganizations(orgIds);
        } else {
          getOrganizations([]);
        }
      })
      .catch((error) => {
        console.log("Error getting org assignments for user", userId, error);
      });
  };

  const getOrganizations = (alreadyAssignedOrgIds) => {
    ticketingServiceApi
      .get("organizations")
      .then((response: any) => {
        const orgs = response.data.organizations.map((org) => ({
          label: org.name,
          value: org.id,
        }));
        const currentOrgsFilteredOut = orgs.filter(
          (org) => !alreadyAssignedOrgIds.includes(org.value)
        );

        setOrganizations(currentOrgsFilteredOut);
        setOrganizationsAreLoading(false);
      })
      .catch((error) => {
        console.log("Error getting orgs", error);
      });
  };

  const portalPlacement = "bottom";

  const orgLogo = (orgLogoUrl?: string) => {
    if (orgLogoUrl) {
      return orgLogoUrl;
    } else {
      return "https://bottega-devcamp.s3.amazonaws.com/support-app/bottega.placeholder-icon.png";
    }
  };

  const currentOrganizationAssignmentsRenderer = () => {
    if (assignmentsAreLoading) {
      return <Loader />;
    }

    if (currentAssignments && currentAssignments.length > 0) {
      return currentAssignments.map((organization) => {
        return (
          <div key={organization.id} className="org-item">
            <div>
              <img
                src={orgLogo(organization.org_logo_url)}
                style={{ width: "100%" }}
              />
            </div>
            <div style={{ fontWeight: 900 }}>{organization.name}</div>
            <div>
              <a onClick={() => handleRemoval(organization.id)}>
                <FontAwesomeIcon icon="user-times" />
              </a>
            </div>
          </div>
        );
      });
    }
  };

  const handleSubmit = (evt: any) => {
    evt.preventDefault();

    if (selectedOrganizations.length === 0) {
      props.handleModalClose();
    } else {
      const params = {
        id: props.userId,
        organization_ids: selectedOrganizations.map((org) => org.value),
      };

      ticketingServiceApi
        .post(`support_tier_organization_assignments`, params)
        .then((response: any) => {
          if (response.data.assigned_organizations) {
            setSelectedOrganizations([]);
            props.handleModalClose();
          } else {
            alert("There was an error adding the org, please contact an admin");
          }
        })
        .catch((error) => {
          alert("There was an error adding the org, please contact an admin");
        });
    }
  };

  const handleRemoval = (orgId) => {
    ticketingServiceApi
      .delete(
        `support_tier_organization_assignments/${props.userId}?organization_id=${orgId}`
      )
      .then((response: any) => {
        if (response.data.msg === "ORG_ASSIGNMENT_NOT_REMOVED") {
          alert("There was an error removing the org, please contact an admin");
        } else {
          const filteredAssignments = currentAssignments.filter(
            (org) => org.id !== orgId
          );
          setCurrentAssignments(filteredAssignments);
        }
      })
      .catch((error) => {
        alert("There was an error removing the org, please contact an admin");
      });
  };

  const handleSelectedOrganizationChange = (value) => {
    selectedOrganizations.filter((org) => value.includes(org));
    setSelectedOrganizations(value);
  };

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        props.handleModalClose();
      }}
      style={customModalStyles}
    >
      <form onSubmit={handleSubmit} className="form-wrapper">
        {errorText ? (
          <div className="form-error">
            <FontAwesomeIcon icon="exclamation-circle" />
            {errorText}
          </div>
        ) : null}

        <div className="org-list">
          {currentOrganizationAssignmentsRenderer()}
        </div>
        <div className="form-group" style={{ marginBottom: "20px" }}>
          {organizationsAreLoading ? null : (
            <Select
              isMulti={true}
              value={selectedOrganizations}
              onChange={handleSelectedOrganizationChange}
              options={organizations}
              placeholder="Select the organization(s) to assign them to"
              classNamePrefix="search-select"
              isClearable={true}
              menuPosition={"fixed"}
              menuPlacement={portalPlacement}
              menuPortalTarget={document.body}
            />
          )}
          <label className="spacer10">Organizations</label>
        </div>

        <div
          className="login-btn-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button className="btn-primary" type="submit">
            Update authorized organizations
          </button>
        </div>
      </form>
    </ReactModal>
  );
};
