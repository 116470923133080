import * as React from "react";
import { useContext } from "react";
import ReactModal from "react-modal";
import ReactMarkdown from "react-markdown";
import Gravatar from "react-gravatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import CurrentUserContext from "../../contexts/CurrentUserContext";
import commentsRenderer from "./comments/commentsRenderer";

ReactModal.setAppElement(".app-wrapper");

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "80vw",
    height: "80vh"
  }
};

interface ITicketDetailModalProps {
  handleModalClose: () => void;
  modalIsOpen: boolean;
  ticket: any;
}

const TicketDetailModal = (props: ITicketDetailModalProps) => {
  const currentUserContext = useContext(CurrentUserContext);
  const {
    title,
    assigned_users,
    content,
    user_email,
    full_name,
    created_at,
    status,
    guide_url,
    rating,
    comments
  } = props.ticket;

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        props.handleModalClose();
      }}
      style={customModalStyles}
    >
      <div className="rating-detail-modal">
        <div className="rating-detail-modal__title">{title}</div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div
            className="ticket-detail__top__heading__left-column__user"
            style={{
              fontSize: "1.3rem"
            }}
          >
            Published {moment(created_at).fromNow()} by {full_name} |{" "}
            {user_email}{" "}
            {guide_url ? (
              <span
                style={{
                  marginLeft: "10px"
                }}
              >
                <a
                  href={`https://${currentUserContext.user.org_selected}.devcamp.com/${guide_url}`}
                  style={{
                    fontWeight: 900,
                    color: "#04965b"
                  }}
                  target="_blank"
                >
                  <FontAwesomeIcon icon="link" /> GUIDE
                </a>
              </span>
            ) : null}
          </div>

          <div className="ticket-detail__top__heading__thumbnail-and-rating">
            <div style={{ display: "flex" }}>
              {assigned_users && assigned_users.length > 0
                ? assigned_users.map(user => (
                    <div key={user.user_email} style={{ marginLeft: "10px" }}>
                      <Gravatar email={user.user_email} />
                    </div>
                  ))
                : null}
            </div>
            {rating ? (
              <div
                style={{
                  fontSize: "1.2em",
                  fontWeight: 900,
                  marginTop: "10px"
                }}
              >
                RATING: {rating} / 10
              </div>
            ) : null}
          </div>
        </div>

        <div
          className="markdown-container"
          style={{ marginTop: "20px", marginBottom: "42px" }}
        >
          <ReactMarkdown source={content} />
        </div>
        <div className="rating-detail-modal__content">
          {commentsRenderer(comments)}
        </div>
      </div>
    </ReactModal>
  );
};

export default TicketDetailModal;
