import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";

import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import Loader from "../helpers/Loader";
import TicketListItem from "./TicketListItem";

interface ITicketSidebarProps {
  content: string;
  contentIsReady: boolean;
}

const TicketSidebar = (props: ITicketSidebarProps) => {
  useEffect(() => {
    if (props.contentIsReady && props.content) {
      getTickets();
    }
  }, [props.contentIsReady, props.content]);

  const [tickets, setTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentRequests, setCurrentRequests] = useState([]);

  let CancelToken;
  let source;
  const getTickets = () => {
    if (currentRequests.length > 0) {
      currentRequests.forEach(req => {
        req.cancel();
      });
      setCurrentRequests([]);
    }

    CancelToken = axios.CancelToken;
    source = CancelToken.source();

    setCurrentRequests([...currentRequests, source]);
    setIsLoading(true);

    ticketingServiceApi
      .get(`ticket_query?query=${props.content}`, {
        cancelToken: source.token
      })
      .then(response => {
        setTickets(response.data.tickets);
        setIsLoading(false);
      });
  };

  const autoCompleteTicketsRenderer = () => {
    if (tickets.length > 0) {
      return tickets.map(ticket => {
        return (
          <TicketListItem
            key={ticket.id}
            ticket={ticket}
            source="TicketSidebar"
            opensInModal={true}
          />
        );
      });
    } else {
      return (
        <div
          style={{
            marginTop: "30px",
            fontSize: "1.5rem",
            fontWeight: 700,
            textAlign: "center"
          }}
        >
          Results will populate here once you fill in the title
        </div>
      );
    }
  };

  return (
    <div className="ticket-sidebar-wrapper">
      <div className="underlined-header" style={{ textAlign: "center" }}>
        Tickets that might help answer your question
      </div>

      <div className="sidebar-scroll-container" style={{ marginTop: "30px" }}>
        {isLoading ? (
          <Loader containedLoader={true} />
        ) : (
          autoCompleteTicketsRenderer()
        )}
      </div>
    </div>
  );
};

export default TicketSidebar;
