import * as React from "react";
import { DateRangePicker } from "react-date-range";
import { useState } from "react";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

interface IDateRangePickerProps {
  filterByDateRange: (any) => void;
  queryRunning?: boolean;
  setDateRange: (any) => void;
}
const DateRange = (props: IDateRangePickerProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleSelect = ranges => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
    props.setDateRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
    });
  };

  const handleSubmit = () => {
    props.filterByDateRange({ startDate, endDate });
  };

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection"
  };

  return (
    <div className="column-flex-end" style={{ marginBottom: "20px" }}>
      <DateRangePicker ranges={[selectionRange]} onChange={handleSelect} />
      {props.queryRunning ? (
        <div className="btn-loading">Retrieving Records...</div>
      ) : (
        <a onClick={handleSubmit} className="btn-primary">
          Search
        </a>
      )}
    </div>
  );
};

export default DateRange;
