import * as React from "react";
import { useRef, useState, useContext, useLayoutEffect } from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { useRect } from "@reach/rect";

import EscalationList from "../escalations/EscalationList";

export default () => {
  const AnimatedContext = React.createContext(null);

  function AnimatedTabs(props) {
    const [selectedRect, setSelectedRect] = useState(null);
    const tabsRef = useRef();
    const tabsRect = useRect(tabsRef);

    return (
      <AnimatedContext.Provider value={setSelectedRect}>
        <Tabs
          {...props}
          ref={tabsRef}
          style={{ ...props.style, position: "relative" }}
        >
          {props.children[0]}

          <div
            style={{
              position: "absolute",
              height: 2,
              background: "#00c274",
              marginTop: -2,
              transition: "all 300ms ease",
              left: selectedRect && selectedRect.left - (tabsRect ? tabsRect.left : 0),
              width: selectedRect && selectedRect.width
            }}
          />

          {props.children[1]}
        </Tabs>
      </AnimatedContext.Provider>
    );
  }

  function AnimatedTab(props) {
    const { isSelected } = props;
    const ref = useRef();
    const rect = useRect(ref, isSelected);
    const setSelectedRect = useContext(AnimatedContext);
    useLayoutEffect(() => {
      if (isSelected) setSelectedRect(rect);
    }, [isSelected, rect, setSelectedRect]);

    return (
      <Tab
        ref={ref}
        {...props}
        style={{
          ...props.style,
          border: "none",
          backgroundColor: "transparent",
          fontSize: "1.5rem",
          fontFamily: "Ubuntu",
          outline: "none",
          fontWeight: "900",
          textTransform: "uppercase",
          color: "#00c274",
          cursor: "pointer"
        }}
      />
    );
  }

  return (
    <AnimatedTabs color="#00c274">
      <TabList style={{ justifyContent: "space-around" }}>
        <AnimatedTab style={{ flex: 1, padding: 21 }}>Pending</AnimatedTab>
        <AnimatedTab style={{ flex: 1, padding: 21 }}>Accepted</AnimatedTab>
        <AnimatedTab style={{ flex: 1, padding: 21 }}>Archived</AnimatedTab>
      </TabList>
      <TabPanels style={{ paddingTop: 20 }}>
        <TabPanel style={{ outline: "none" }}>
          <EscalationList escalationType="pending" />
        </TabPanel>

        <TabPanel style={{ outline: "none" }}>
          <EscalationList escalationType="accepted" />
        </TabPanel>

        <TabPanel style={{ outline: "none" }}>
          <EscalationList escalationType="archived" />
        </TabPanel>
      </TabPanels>
    </AnimatedTabs>
  );
};
