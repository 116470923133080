import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router";

import ActiveChatContext from "../../contexts/ActiveChatContext";
import AlertContext from "../../contexts/AlertContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ChatRoomLink = props => {
  const [activeAlert, setActiveAlert] = useState(false);
  const { title, thumb, token } = props.chatRoom;
  const alertContext = useContext(AlertContext);
  const activeChatContext = useContext(ActiveChatContext);

  useEffect(() => {
    if (alertContext.hasAlert(token, "support_chat_room")) {
      setActiveAlert(true);
    } else {
      setActiveAlert(false);
    }
  }, [alertContext.alerts.length]);

  const handleClick = () => {
    if (
      alertContext.alerts &&
      alertContext.hasAlert(token, "support_chat_room")
    ) {
      alertContext.deleteAlertFromApi(token);
      setActiveAlert(false);
    }

    activeChatContext.setActiveChat(props.chatRoom);
  };

  return (
    <div
      className="ticket-sidebar-wrapper__items-wrapper__item"
      onClick={handleClick}
    >
      <div className="ticket-sidebar-wrapper__items-wrapper__item__left">
        <img
          src={thumb}
          className="ticket-sidebar-wrapper__items-wrapper__item__left__thumb"
        />
        <div className="ticket-sidebar-wrapper__items-wrapper__item__left__title">
          {title}
          <span>{props.tempCounter}</span>
        </div>
      </div>

      <div className="ticket-sidebar-wrapper__items-wrapper__item__alert">
        {activeAlert ? <FontAwesomeIcon icon="bell" /> : null}
      </div>
    </div>
  );
};

export default withRouter(ChatRoomLink);
