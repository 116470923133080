import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import SweetAlert from "sweetalert-react";

import { formatErrors } from "../../helpers/formatHelpers";

interface IPhoneNumberFormProps {
  phoneNumber?: any;
  setPhone: (string) => void;
  userId: number;
}
export default (props: IPhoneNumberFormProps) => {
  const [phone, setPhone] = useState("");
  const [formIsActive, setFormIsActive] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [showValidationError, setShowValidationError] = useState(false);
  const { phoneNumber, userId } = props;

  useEffect(() => {
    setPhone(phoneNumber);
  }, []);

  const handleSubmit = evt => {
    const params = {
      phone: phone,
      user_id: userId
    };

    ticketingServiceApi
      .patch("support_tier_phone_assignments/_", params)
      .then(response => {
        if (response.data.support_tier_assignment) {
          props.setPhone(response.data.support_tier_assignment.phone);
          setFormIsActive(false);
        } else if (response.data.errors) {
          setErrorText(formatErrors(response.data.errors));
          setShowValidationError(true);
          setFormIsActive(false);
        }
      })
      .catch(error => {
        setFormIsActive(false);
      });

    evt.preventDefault();
  };

  const form = (
    <form onSubmit={handleSubmit}>
      <div className="input-with-attached-icon-and-cancel">
        <PhoneInput
          defaultCountry="US"
          placeholder="Enter phone number"
          value={phone}
          onChange={setPhone}
        />

        {props.phoneNumber ? (
          <a onClick={() => setFormIsActive(false)} className="cancel-link">
            <FontAwesomeIcon icon="times" />
          </a>
        ) : null}
        <button type="submit">
          <FontAwesomeIcon icon="save" />
        </button>
      </div>
    </form>
  );

  const editablePhoneNumber = () => {
    if (!formIsActive && phoneNumber) {
      return (
        <div className="phone-number-wrapper">
          <div className="phone-number-wrapper__icon">
            <FontAwesomeIcon icon="phone" />
          </div>
          <a onClick={() => setFormIsActive(true)}>{phone}</a>
        </div>
      );
    } else {
      return form;
    }
  };

  return (
    <div>
      <SweetAlert
        show={showValidationError}
        title="Validation error"
        text={errorText}
        onConfirm={() => setShowValidationError(false)}
        onEscapeKey={() => setShowValidationError(false)}
        onOutsideClick={() => setShowValidationError(false)}
        confirmButtonColor="#00c274"
      />

      {editablePhoneNumber()}
    </div>
  );
};
