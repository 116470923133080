import * as React from "react";

interface ICsvGeneratorButtonProps {
  dateRange?: {
    startDate: string;
    endDate: string;
  };
}
const CsvGeneratorButton = (props: ICsvGeneratorButtonProps) => {
  if (props.dateRange) {
    return (
      <div style={{ marginTop: "21px" }}>
        <a
          href={`${process.env.TICKET_SERVICE_API_URL}csv_reports.csv?start_date=${props.dateRange.startDate}&end_date=${props.dateRange.endDate}`}
          target="_blank"
          className="btn-primary"
        >
          CSV Report
        </a>
      </div>
    );
  } else {
    return null;
  }
};

export default CsvGeneratorButton;
