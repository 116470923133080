import * as React from "react";
import moment from "moment";

interface IMonthlyStatBoxProps {
  ticketCount: number;
  averageResponseTime: string;
  averageRating: string;
}
const MonthlyStatBox = (props: IMonthlyStatBoxProps) => {
  const { ticketCount, averageResponseTime, averageRating } = props;

  const secondsToFormattedMinutes = seconds => {
    if (seconds) {
      const duration: any = moment.duration(seconds, "seconds");
      return duration.format("hh:mm:ss");
    } else {
      return "";
    }
  };

  const formatRating = rating => {
    if (rating) {
      return parseFloat(rating).toFixed(1);
    } else {
      return "";
    }
  };

  return (
    <div className="monthly-summary-stat-box">
      <div className="data-box">
        <div className="data-box__heading">{ticketCount}</div>
        <div className="data-box__subheading">TICKETS</div>
      </div>

      <div className="data-box">
        <div className="data-box__heading">{formatRating(averageRating)}</div>
        <div className="data-box__subheading">AVG RATING</div>
      </div>

      <div className="data-box">
        <div className="data-box__heading">
          {secondsToFormattedMinutes(averageResponseTime)}
        </div>
        <div className="data-box__subheading">AVG RESPONSE TIME</div>
      </div>
    </div>
  );
};

export default MonthlyStatBox;
