import * as React from "react";
import { useState, useContext, useEffect } from "react";
import ReactModal from "react-modal";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Gravatar from "react-gravatar";

import ActiveChatContext from "../../contexts/ActiveChatContext";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import Loader from "../helpers/Loader";

ReactModal.setAppElement(".app-wrapper");

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "600px"
  }
};

interface IManageChatModal {
  handleModalClose: () => void;
  modalIsOpen: boolean;
}

const ManageChatModal = (props: IManageChatModal) => {
  const activeChatContext = useContext(ActiveChatContext);
  const [chatRoomTitle, setChatRoomTitle] = useState("");
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoCompleteUsers, setAutoCompleteUsers] = useState([]);
  const [errorText, setErrorText] = useState(null);
  const [usersAreLoading, setUsersAreLoading] = useState(true);

  useEffect(() => {
    if (props.modalIsOpen) {
      setChatRoomTitle(activeChatContext.chatRoom.title);
      getCurrentUsers();
    }
  }, [props.modalIsOpen, activeChatContext.chatRoom.id]);

  useEffect(() => {
    if (!props.modalIsOpen) {
      setBaseState();
    }
  }, [props.modalIsOpen]);

  const setBaseState = () => {
    setChatRoomTitle("");
    setUsersToAdd([]);
    setCurrentUsers([]);
    setSearchValue("");
    setAutoCompleteUsers([]);
    setErrorText(null);
    setUsersAreLoading(true);
  };

  const getCurrentUsers = () => {
    let userIdQuery = "";

    if (activeChatContext.chatRoom.users) {
      userIdQuery = activeChatContext.chatRoom.users.join(",");
    }

    ticketingServiceApi
      .get(`users?user_ids=${userIdQuery || ""}`)
      .then(response => {
        setCurrentUsers(response.data.users);
        setUsersAreLoading(false);
      })
      .catch(error => {
        // TODO show error
      });
  };

  const handleUpdateChatRoom = async event => {
    event.preventDefault();

    let currentUserIds;
    if (activeChatContext.chatRoom.users) {
      currentUserIds = activeChatContext.chatRoom.users;
    }

    const idsToAdd = usersToAdd.map(u => u.value);
    let roomType;

    if (idsToAdd.length + currentUserIds.length > 2) {
      roomType = "group_room";
    } else {
      roomType = "direct_message";
    }

    const chatRoomData = {
      support_chat_room: {
        title: chatRoomTitle,
        chat_type: roomType,
        users: [...currentUserIds, ...idsToAdd]
      }
    };

    await updateChatRoomOnApi(chatRoomData);
    props.handleModalClose();
  };

  const handleUserRemoval = async userId => {
    const filteredUsers = currentUsers.filter(u => u.id !== userId);
    setCurrentUsers(filteredUsers);
  };

  const updateChatRoomOnApi = chatRoomData => {
    ticketingServiceApi
      .patch(
        `support_chat_rooms/${activeChatContext.chatRoom.token}`,
        chatRoomData
      )
      .then(response => {
        activeChatContext.setActiveChat(response.data.support_chat_room);
      })
      .catch(error => {
        // TODO
        // show an error
      });
  };

  const handleChange = value => {
    usersToAdd.filter(user => value.includes(user));
    setUsersToAdd(value);
  };

  const portalPlacement = "bottom";

  const handleQuery = inputValue => {
    setSearchValue(inputValue);

    if (inputValue && inputValue.length > 2) {
      ticketingServiceApi
        .get(`search-users?query=${inputValue}`)
        .then(response => {
          const users = response.data.users.map(user => {
            return {
              label: `${user.first_name} ${user.last_name} ${user.email}`,
              value: user.id
            };
          });

          if (users && users.length > 0) {
            setAutoCompleteUsers(users);
          }
        })
        .catch(error => {
          // TODO
          // show error
        });
    }
  };

  const currentUserRenderer = () => {
    if (usersAreLoading) {
      return <Loader />;
    }

    if (currentUsers && currentUsers.length > 0) {
      return currentUsers.map(user => {
        return (
          <div key={user.id} className="user-item">
            <div>
              <Gravatar email={user.email} />
            </div>
            <div style={{ fontWeight: 900 }}>
              {user.first_name} {user.last_name}
            </div>
            <div>{user.email}</div>
            <div>
              <a onClick={() => handleUserRemoval(user.id)}>
                <FontAwesomeIcon icon="user-times" />
              </a>
            </div>
          </div>
        );
      });
    }
  };

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        props.handleModalClose();
      }}
      style={customModalStyles}
    >
      <form onSubmit={handleUpdateChatRoom} className="form-wrapper">
        {errorText ? (
          <div className="form-error">
            <FontAwesomeIcon icon="exclamation-circle" />
            {errorText}
          </div>
        ) : null}

        <div className="form-group" style={{ marginBottom: "20px" }}>
          <input
            value={chatRoomTitle}
            onChange={e => setChatRoomTitle(e.target.value)}
            className="text-field"
            required
          />
          <label>Room name</label>
        </div>
        <div className="user-list">{currentUserRenderer()}</div>
        <div className="form-group" style={{ marginBottom: "20px" }}>
          <Select
            isMulti={true}
            inputValue={searchValue}
            onInputChange={val => handleQuery(val)}
            value={usersToAdd}
            onChange={handleChange}
            options={autoCompleteUsers}
            styles={{
              menuPortal: base => ({ ...base, zIndex: 9999, fontSize: "1.3em" })
            }}
            menuPosition={"fixed"}
            menuPlacement={portalPlacement}
            menuPortalTarget={document.body}
            placeholder="Search via email or name"
            classNamePrefix="search-select"
            isClearable={true}
          />
        </div>

        <div
          className="login-btn-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <button className="btn-primary" type="submit">
            Update chatroom
          </button>
        </div>
      </form>
    </ReactModal>
  );
};

export default ManageChatModal;
