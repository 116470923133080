import * as React from "react";
import { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useInput } from "../../../hooks/useInput";
import ActiveTicketContext from "../../../contexts/ActiveTicketContext";
import ticketingServiceApi from "../../../helpers/ticketingServiceApi";
import MarkdownIcon from "../../svgs/MarkdownIcon";

const CommentForm = () => {
  const [commentIsBeingSubmitted, setCommentIsBeingSubmitted] = useState(false);
  const { value: message, bind: bindMessage, reset: resetMessage } = useInput(
    ""
  );
  const activeTicketContext = useContext(ActiveTicketContext);

  const handleSubmit = event => {
    setCommentIsBeingSubmitted(true);
    ticketingServiceApi
      .post(`tickets/${activeTicketContext.ticket.id}/comments`, {
        comment: {
          message: message
        }
      })
      .then(response => {
        resetMessage();
        setCommentIsBeingSubmitted(false);
      })
      .catch(error => {
        // TODO
      });
    event.preventDefault();
  };

  const customTextAreaStyles = {
    height: "150px"
  };

  return (
    <div className="comments-container__form-container">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <textarea
            required
            {...bindMessage}
            className="textarea-field"
            style={customTextAreaStyles}
          />
          <label>
            <span />
            <span>
              <span className="markdown-icon">
                <MarkdownIcon />
              </span>{" "}
              Supported
            </span>
          </label>
        </div>

        {commentIsBeingSubmitted ? (
          <button className="btn-loading" type="submit">
            <FontAwesomeIcon icon="spinner" spin />
          </button>
        ) : (
          <button type="submit" className="btn-primary">
            Add response <FontAwesomeIcon icon="comment-medical" />
          </button>
        )}
      </form>
    </div>
  );
};

export default CommentForm;
