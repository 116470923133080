import * as React from "react";
import { useState } from "react";

import DashboardLayout from "../../components/layouts/DashboardLayout";
import ChatTabsWrapper from "../../components/tabs/ChatTabsWrapper";
import ChatRoomDetail from "../../components/chat/ChatRoomDetail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewChatModal from "../../components/chat/NewChatModal";

const ChatDashboard = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleNewChatModalClick = () => {
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  return (
    <DashboardLayout>
      <NewChatModal
        modalIsOpen={modalIsOpen}
        handleModalClose={handleModalClose}
      />
      <div className="grid-1fr-3fr">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "calc(100vh - 180px)"
          }}
        >
          <ChatTabsWrapper />
          <a
            style={{
              width: "100%",
              fontSize: "2em",
              fontWeight: 900,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            onClick={handleNewChatModalClick}
          >
            <span style={{ marginRight: "15px" }}>NEW CHAT</span>
            <FontAwesomeIcon icon="plus-square" />
          </a>
        </div>
        <ChatRoomDetail />
      </div>
    </DashboardLayout>
  );
};

export default ChatDashboard;
