import React from "react";
import ReactMarkdown from "react-markdown";
import Gravatar from "react-gravatar";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";

interface IEscalationBannerProps {
  escalation: {
    id: number;
    previous_admin_email: string;
    previous_admin_id: string;
    rationale: string;
    ticket_id: number;
    status: string;
  };
}

export default (props: IEscalationBannerProps) => {
  const {
    id,
    previous_admin_email,
    rationale,
    ticket_id,
    status
  } = props.escalation;

  const handleClick = (evt: { preventDefault: () => void }) => {
    ticketingServiceApi
      .patch(`tickets/${ticket_id}/ticket_escalations/${id}`, {})
      .then(response => {
        window.location.href = `/ticket/${ticket_id}`;
      })
      .catch(error => {
        console.log("ERROR UPDATING THE TICKET ESCALATION", error);
      });

    evt.preventDefault();
  };

  return (
    <div className="escalation-banner">
      <div className="markdown-container">
        <ReactMarkdown source={rationale} />
      </div>

      <div className="escalation-banner__admin-wrapper">
        <div className="left">
          <div className="content">Escalated from:</div>
          <Gravatar email={previous_admin_email} />
          <div className="admin-email">{previous_admin_email}</div>
        </div>

        <div>
          {status === "pending" ? (
            <a className="btn-white" onClick={handleClick}>
              Accept
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};
