import * as React from "react";
import { ActionCableConsumer } from "react-actioncable-provider";

import ticketingServiceApi from "../helpers/ticketingServiceApi";
import ActiveChatContext from "../contexts/ActiveChatContext";

export default class ActiveChatProvider extends React.Component {
  state = {
    roomChannel: React.createRef() as any,
    chatRoomId: null,
    chatRoomToken: null,
    chatRoom: { id: null, token: null },
    showChatRoom: false,
    liveChatMessages: [],
    setActiveChat: chatRoom => {
      this.setState({
        chatRoomToken: chatRoom.token,
        chatRoomId: chatRoom.id,
        chatRoom: chatRoom,
        showChatRoom: true,
        liveChatMessages: chatRoom.messages,
        roomChannel: React.createRef()
      });
    },
    clearActiveChat: () => {
      this.setState({
        chatRoom: { id: null, token: null },
        chatRoomId: null,
        chatRoomToken: null,
        showChatRoom: false,
        liveChatMessages: [],
        roomChannel: React.createRef()
      });
    },
    submitMessage: messageToAdd => {
      this.sendMessageToChannel(messageToAdd);
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.chatRoomToken &&
      nextState.chatRoomToken !== this.state.chatRoomToken
    ) {
      this.getChatRoomDetails(nextState.chatRoomToken);
      return true;
    } else if (nextState.chatRoom.token && !this.state.chatRoom.token) {
      this.getChatRoomDetails(nextState.chatRoomToken);
      return true;
    } else if (
      nextState.liveChatMessages.length !== this.state.liveChatMessages
    ) {
      return true;
    } else {
      return false;
    }
  }

  getChatRoomDetails = chatRoomToken => {
    ticketingServiceApi
      .get(`support_chat_rooms/${chatRoomToken}`)
      .then(response => {
        this.setState({
          chatRoom: response.data.support_chat_room,
          liveChatMessages: response.data.support_chat_room.messages,
          showChatRoom: true
        });
      })
      .catch(error => {
        // TODO
      });
  };

  sendMessageToChannel = message => {
    this.state.roomChannel.current.perform("send_message", { message });
  };

  handleReceivedMessage = message => {
    this.setState({
      liveChatMessages: [...this.state.liveChatMessages, message]
    });
  };

  render() {
    return (
      <ActiveChatContext.Provider value={this.state}>
        {this.state.chatRoom.token ? (
          <ActionCableConsumer
            ref={this.state.roomChannel}
            channel={{
              channel: `SupportChatRoomChannel`,
              id: this.state.chatRoom.token
            }}
            onReceived={this.handleReceivedMessage}
          />
        ) : null}

        {this.props.children}
      </ActiveChatContext.Provider>
    );
  }
}
