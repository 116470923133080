import React, { useState, useEffect } from "react";
import _ from "lodash";
import Gravatar from "react-gravatar";
import moment from "moment";

import DashboardLayout from "../../components/layouts/DashboardLayout";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import Loader from "../../components/helpers/Loader";

export default () => {
  const [dutyEntries, setDutyEntries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDutyEntries();
  }, []);

  const getDutyEntries = () => {
    ticketingServiceApi
      .get("duty_entry_reports")
      .then((response) => {
        setDutyEntries(response.data.duty_entries);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("ERROR GETTING DUTY ENTRIES", error);
      });
  };

  const dutyEntriesRenderer = () => {
    if (dutyEntries.length > 0) {
      return dutyEntries.map((dutyEntry) => {
        return (
          <div className="duty-entries-wrapper__item" key={dutyEntry.id}>
            <div>
              <Gravatar email={dutyEntry.user_email} />
            </div>
            <div>{dutyEntry.user_email}</div>
            <div>{dutyEntry.full_name}</div>
            <div>
              {moment(dutyEntry.start_time).format("MMM Do YYYY h:mm:ss a")}
            </div>
            <div>
              {dutyEntry.end_time
                ? moment(dutyEntry.end_time).format("MMM Do YYYY h:mm:ss a")
                : "n/a"}
            </div>
          </div>
        );
      });
    }
  };

  if (isLoading) {
    <Loader containedLoader={true} />;
  }

  return (
    <DashboardLayout>
      <div className="duty-entries-wrapper">{dutyEntriesRenderer()}</div>
    </DashboardLayout>
  );
};
