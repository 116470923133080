import * as React from "react";
import { useState, useContext } from "react";
import ReactModal from "react-modal";

import MarkdownIcon from "../svgs/MarkdownIcon";
import ActiveChatContext from "../../contexts/ActiveChatContext";

ReactModal.setAppElement(".app-wrapper");

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "80%"
  }
};

interface IMarkdownEditorChatModal {
  handleModalClose: () => void;
  modalIsOpen: boolean;
}

const MarkdownEditorChatModal = (props: IMarkdownEditorChatModal) => {
  const activeChatContext = useContext(ActiveChatContext);
  const [content, setContent] = useState("");

  const handleMarkdownSubmit = event => {
    event.preventDefault();
    activeChatContext.submitMessage(content);
    setContent("");
    props.handleModalClose();
  };

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        setContent("");
        props.handleModalClose();
      }}
      style={customModalStyles}
    >
      <form onSubmit={handleMarkdownSubmit} className="form-wrapper">
        <div className="form-group">
          <textarea
            onChange={e => setContent(e.target.value)}
            value={content}
            required
            className="textarea-field"
          />
          <label>
            Content{" "}
            <span>
              <span className="markdown-icon">
                <MarkdownIcon />
              </span>{" "}
              Supported
            </span>
          </label>
        </div>

        <div className="button-wrapper">
          <button className="btn-primary" type="submit">
            Submit
          </button>
        </div>
      </form>
    </ReactModal>
  );
};

export default MarkdownEditorChatModal;
