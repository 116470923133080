import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Gravatar from "react-gravatar";
import Truncate from "react-truncate";
import moment from "moment";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import timeCalculator from "../../helpers/timeCalculator";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import Loader from "../../components/helpers/Loader";
import DateRange from "../../components/helpers/DateRange";
import MonthlyStatBox from "../../charts/MonthlyStatBox";
import CsvGeneratorButton from "../../components/reports/CsvGeneratorButton";

const PerformanceDashboard = () => {
  const [tickets, setTickets] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [dateRangeQueryRunning, setDateRangeQueryRunning] = useState(false);
  const [dateRangeStatData, setDateRangeStatData] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [ticketActivitiesAreLoading, setTicketActivitiesAreLoading] = useState(
    true
  );
  const [infiniteScrollIsLoading, setInfiniteScrollIsLoading] = useState(false);
  const [
    errorGettingTicketActivities,
    setErrorGettingTicketActivities,
  ] = useState(false);

  useEffect(() => {
    getTicketActivities();
  }, []);

  useEffect(() => {
    activateInfiniteScroll();
  });

  const activateInfiniteScroll = () => {
    window.onscroll = () => {
      if (
        errorGettingTicketActivities ||
        infiniteScrollIsLoading ||
        tickets.length === totalCount ||
        !hasMore
      ) {
        return;
      }

      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        getTicketActivities();
      }
    };
  };

  const getTicketActivities = (dateRange = null) => {
    let dateRangeQuery = "";
    setDateRangeStatData(null);

    if (dateRange) {
      setDateRangeQueryRunning(true);
      dateRangeQuery = `&start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`;
      getDateRangeStats(
        `?start_date=${dateRange.startDate}&end_date=${dateRange.endDate}`
      );
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage + 1);
    }

    if (currentPage >= 1) {
      setInfiniteScrollIsLoading(true);
    }

    ticketingServiceApi
      .get(`ticket_activities?page=${currentPage}${dateRangeQuery}`)
      .then((response) => {
        let uniqueTickets;

        if (dateRangeQuery) {
          setDateRangeQueryRunning(false);
          uniqueTickets = response.data.tickets;
        } else {
          uniqueTickets = _.uniqBy(
            tickets.concat([...response.data.tickets]),
            "id"
          );
        }

        if (response.data.tickets.length === 0) {
          setHasMore(false);
        }

        setTickets(uniqueTickets);
        setTicketActivitiesAreLoading(false);
        setInfiniteScrollIsLoading(false);
        setTotalCount(response.data.meta.total_records);
      })
      .catch((error) => {
        // TODO
      });
  };

  const activityRenderer = (ticket, status) => {
    if (ticket.ticket_activities && ticket.ticket_activities.length > 0) {
      const date = _.find(ticket.ticket_activities, ["status", status]);

      if (date) {
        const ticketDate = moment(ticket.created_at);
        const activityDate = moment(date.created_at);
        const differenceInSeconds = ticketDate.diff(activityDate, "seconds");
        return timeCalculator(differenceInSeconds);
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const studentIcon = (t) => {
    const student = t.assigned_users.filter((u) => u.ticket_creator)[0];

    if (student) {
      return <Gravatar email={student.user_email} />;
    } else {
      return <div style={{ width: "100%" }} />;
    }
  };

  const mentorIcon = (t) => {
    const mentor = t.assigned_users.filter((u) => !u.ticket_creator)[0];

    if (mentor) {
      return <Gravatar email={mentor.user_email} />;
    } else {
      return <div style={{ width: "100%" }} />;
    }
  };

  const mentorName = (t) => {
    const mentor = t.assigned_users.filter((u) => !u.ticket_creator)[0];

    if (mentor) {
      return (
        <div
          style={{
            textTransform: "uppercase",
            color: "#333333",
          }}
        >
          {mentor.full_name}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const mentorId = (t) => {
    const mentor = t.assigned_users.filter((u) => !u.ticket_creator)[0];

    if (mentor) {
      return mentor.user_id;
    } else {
      return null;
    }
  };

  const studentId = (t) => {
    const student = t.assigned_users.filter((u) => u.ticket_creator)[0];

    if (student) {
      return student.user_id;
    } else {
      return null;
    }
  };

  const studentName = (t) => {
    const student = t.assigned_users.filter((u) => u.ticket_creator)[0];

    if (student) {
      return (
        <div
          style={{
            textTransform: "uppercase",
            color: "#333333",
          }}
        >
          {student.full_name}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const ticketActivitiesRenderer = () => {
    if (tickets.length > 0) {
      return tickets.map((ticket) => {
        return (
          <tr key={ticket.id}>
            <td>
              {mentorId(ticket) ? (
                <Link to={`/u/${mentorId(ticket)}`}>{mentorIcon(ticket)}</Link>
              ) : (
                mentorIcon(ticket)
              )}
            </td>
            <td>
              {mentorId(ticket) ? (
                <Link to={`/u/${mentorId(ticket)}`}>{mentorName(ticket)}</Link>
              ) : (
                mentorName(ticket)
              )}
            </td>
            <td>
              {studentId(ticket) ? (
                <Link to={`/u/${studentId(ticket)}`}>
                  {studentIcon(ticket)}
                </Link>
              ) : (
                studentIcon(ticket)
              )}
            </td>
            <td>
              {studentId(ticket) ? (
                <Link to={`/u/${studentId(ticket)}`}>
                  {studentName(ticket)}
                </Link>
              ) : (
                studentName(ticket)
              )}
            </td>
            <td>{ticket.org_selected ? ticket.org_selected : "n/a"}</td>
            <td>
              <Link to={`/ticket/${ticket.id}`}>
                <Truncate lines={1} width={300}>
                  {ticket.title}
                </Truncate>
              </Link>
            </td>

            <td>{moment(ticket.created_at).format("MM-D-YY h:mm:ss a")}</td>

            <td>{activityRenderer(ticket, "first_comment")}</td>
            <td>{activityRenderer(ticket, "in_progress")}</td>
            <td>{activityRenderer(ticket, "resolved")}</td>
            <td>
              {ticket.rating ? (
                <div style={{ marginRight: "10px" }}>{ticket.rating} / 10</div>
              ) : (
                <div>N/A</div>
              )}
            </td>
            <td>{ticket.view_count}</td>
          </tr>
        );
      });
    } else {
      return null;
    }
  };

  const handleClearDateFilter = () => {
    setShowDateRangePicker(false);
    setCurrentPage(0);
    setTickets([]);
    setDateRangeStatData(null);
    getTicketActivities();
  };

  const getDateRangeStats = (dateRangeQuery: string) => {
    ticketingServiceApi
      .get(`date_range_stats${dateRangeQuery}`)
      .then((response) => {
        setDateRangeStatData({
          count: response.data.total_tickets,
          averageResponseTime: response.data.average_response_time,
          averageRating: response.data.average_rating,
        });
      })
      .catch((error) => {
        // TODO
      });
  };

  return (
    <DashboardLayout>
      {ticketActivitiesAreLoading ? (
        <Loader containedLoader={true} />
      ) : (
        <div>
          <div className="split-heading-wrapper">
            <div>
              <div className="page-heading" style={{ marginBottom: "20px" }}>
                Ticket Performance
              </div>
              {dateRangeStatData ? (
                <MonthlyStatBox
                  averageRating={dateRangeStatData.averageRating}
                  averageResponseTime={dateRangeStatData.averageResponseTime}
                  ticketCount={dateRangeStatData.count}
                />
              ) : null}
              <CsvGeneratorButton dateRange={dateRange} />
            </div>

            {showDateRangePicker ? (
              <div className="column-flex-end">
                <a
                  className="page-heading"
                  style={{ textAlign: "right", marginBottom: "20px" }}
                  onClick={handleClearDateFilter}
                >
                  Clear Date Filter
                  <FontAwesomeIcon icon="chevron-circle-up" />
                </a>
                <DateRange
                  setDateRange={setDateRange}
                  filterByDateRange={getTicketActivities}
                  queryRunning={dateRangeQueryRunning}
                />
              </div>
            ) : (
              <div>
                <a
                  className="page-heading"
                  onClick={() => setShowDateRangePicker(true)}
                >
                  Filter Date Range
                  <FontAwesomeIcon icon="chevron-circle-down" />
                </a>
              </div>
            )}
          </div>
          <div>
            <table style={{ width: "98%" }}>
              <thead>
                <tr>
                  <th colSpan={2}>MENTOR</th>
                  <th colSpan={2}>STUDENT</th>
                  <th>Org</th>
                  <th>TICKET</th>
                  <th>CREATED</th>
                  <th>1ST COMMENT</th>
                  <th>ASSIGNED</th>
                  <th>RESOLVED</th>
                  <th>RATING</th>
                  <th>VIEWS</th>
                </tr>
              </thead>
              <tbody>{ticketActivitiesRenderer()}</tbody>
            </table>
            {infiniteScrollIsLoading ? <Loader containedLoader={true} /> : null}
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default PerformanceDashboard;
