import * as React from "react";

import Loader from "../helpers/Loader";
import ChatRoomLink from "./ChatRoomLink";

interface IChatListProps {
  chatRooms: any[];
  isLoading: boolean;
}

const ChatRoomList = (props: IChatListProps) => {
  const { isLoading, chatRooms } = props;

  const chatRoomListRenderer = () => {
    if (isLoading) {
      return <Loader containedLoader={true} />;
    } else if (chatRooms.length > 0) {
      return chatRooms.map((chatRoom, idx) => {
        return <ChatRoomLink key={idx} chatRoom={chatRoom} />;
      });
    } else if (chatRooms && chatRooms.length === 0) {
      return <h2>There are currently no chats</h2>;
    } else {
      return null;
    }
  };

  return (
    <div className="sidebar-scroll-container">{chatRoomListRenderer()}</div>
  );
};

export default ChatRoomList;
