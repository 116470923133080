import * as React from "react";
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";
import CurrentUserContext from "../contexts/CurrentUserContext";
import {
  routesForLoggedInUsers,
  routesForSuperAdmins,
  routesForMentors
} from "./routes";
import NoMatch from "../pages/errors/NoMatch";

const App = () => {
  return (
    <div className="app">
      <Router>
        <div>
          <Switch>
            <CurrentUserContext.Consumer>
              {(context: {
                loggedInStatus: string;
                user: any;
                permissions: { role: string };
              }) => {
                if (context.loggedInStatus === "LOGGED_IN" && context.user) {
                  if (context.permissions.role === "admin") {
                    return routesForSuperAdmins
                  } else if (context.permissions.role === "mentor") {
                    return routesForMentors
                  } else {
                    return routesForLoggedInUsers
                  }
                } else {
                  const url = new URL("/users/sign_in", process.env.DEVCAMP_API_URL)
                  url.searchParams.set("redirect_to", window.location.href)
                  window.location.assign(url.toString());
                }
              }}
            </CurrentUserContext.Consumer>

            <Route component={NoMatch} />
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default App;
