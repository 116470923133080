import * as React from "react";
import ReactModal from "react-modal";
import ReactMarkdown from "react-markdown";

ReactModal.setAppElement(".app-wrapper");

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "900px"
  }
};

interface IExampleTicketModalModal {
  handleModalClose: () => void;
  modalIsOpen: boolean;
}

const ExampleTicketModal = (props: IExampleTicketModalModal) => {
  const content = `
The exercise asks to:

Create a sum function that adds two arguments together and returns the sum of the two numbers.

I've been trying a few things but they all seem to fail. Here's my code:

\`\`\`
function numberSum(arg1,arg2) { sum = arg1 + arg2; return sum; }
\`\`\`

\`\`\`
console.log(numberSum(4, 5));
\`\`\`

I'd appreciate knowing where my error is! Thanks!
`;

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      onRequestClose={() => {
        props.handleModalClose();
      }}
      style={customModalStyles}
    >
      <div className="rating-detail-modal">
        <div className="rating-detail-modal__title">
          Example Title: Coding Exercise Help in How to Work with Function
          Arguments in JavaScript
        </div>
        <div
          style={{ marginBottom: "20px", fontSize: "1.5em", fontWeight: 900 }}
        >
          Example Content:
        </div>
        <div className="markdown-container">
          <ReactMarkdown source={content} />
        </div>
      </div>
    </ReactModal>
  );
};

export default ExampleTicketModal;
