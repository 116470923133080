import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

import AlertContext from "../../contexts/AlertContext";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import BLogo from "../svgs/BLogo";

const NavLinks = () => {
  const alertContext = useContext(AlertContext);
  const currentUserContext = useContext(CurrentUserContext);
  const [hasChatAlerts, setHasChatAlerts] = useState(false);
  const [hasTicketAlerts, setHasTicketAlerts] = useState(false);

  useEffect(() => {
    if (alertContext.alerts && alertContext.alerts.length > 0) {
      const uniqByResourceId = _.uniqBy(alertContext.alerts, "resource_id");
      document.title = `(${uniqByResourceId.length}) - Bottega Support`;
    } else {
      document.title = "Bottega Support";
    }

    if (alertContext.hasChatAlerts()) {
      setHasChatAlerts(true);
    } else {
      setHasChatAlerts(false);
    }

    if (alertContext.hasTicketAlerts()) {
      setHasTicketAlerts(true);
    } else {
      setHasTicketAlerts(false);
    }
  }, [alertContext.alerts.length]);

  return (
    <div className="nav-links-wrapper">
      <Link to="/">
        <div className="b-logo">
          <BLogo />
        </div>
      </Link>

      <Link to="/tickets">
        <FontAwesomeIcon icon="clipboard-list" /> Tickets
        {hasTicketAlerts ? (
          <span className="chat-alert">
            <FontAwesomeIcon icon="bell" />
          </span>
        ) : null}
      </Link>

      <Link to="/new-ticket">
        <FontAwesomeIcon icon="plus-square" /> New Ticket
      </Link>

      <Link to="/chat">
        <FontAwesomeIcon icon="comments" /> Chat
        {hasChatAlerts ? (
          <span className="chat-alert">
            <FontAwesomeIcon icon="bell" />
          </span>
        ) : null}
      </Link>

      <Link to="/alerts">
        <FontAwesomeIcon icon="bell" /> Alerts
        {hasChatAlerts || hasTicketAlerts ? (
          <span className="chat-alert">
            <FontAwesomeIcon icon="bell" />
          </span>
        ) : null}
      </Link>
    </div>
  );
};

export default NavLinks;
