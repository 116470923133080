import * as React from "react";
import { useContext, useEffect } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AlertContext from "../../contexts/AlertContext";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import { Link } from "react-router-dom";

const AlertDashboard = () => {
  const alertContext = useContext(AlertContext);

  useEffect(() => {
    alertContext.retrieveAlerts();
  }, []);

  const alertRenderer = () => {
    if (alertContext.alerts && alertContext.alerts.length > 0) {
      const uniqByResourceId = _.uniqBy(alertContext.alerts, "resource_id");

      return uniqByResourceId.map((alert: any) => {
        return (
          <div
            className="ticket-sidebar-wrapper__items-wrapper__item"
            key={alert.id}
          >
            <div className="ticket-sidebar-wrapper__items-wrapper__item__left">
              <div className="ticket-sidebar-wrapper__items-wrapper__item__left__title">
                {alert.alert_type === "ticket" ? (
                  <Link
                    onClick={() =>
                      alertContext.deleteAlertFromApi(alert.resource_id)
                    }
                    to={`ticket/${alert.resource_id}`}
                  >
                    {alert.message}
                  </Link>
                ) : (
                  <Link
                    onClick={() =>
                      alertContext.deleteAlertFromApi(alert.resource_id)
                    }
                    to={`chat/${alert.resource_id}`}
                  >
                    {alert.message}
                  </Link>
                )}
              </div>
            </div>

            <div className="ticket-sidebar-wrapper__items-wrapper__item__alert">
              <a
                onClick={() =>
                  alertContext.deleteAlertFromApi(alert.resource_id)
                }
              >
                <FontAwesomeIcon icon="trash" />
              </a>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div style={{ fontSize: "2em", textAlign: "center" }}>
          Yay! No alerts
        </div>
      );
    }
  };

  return (
    <DashboardLayout>
      <div className="centered-flex-wrapper flex-columns">
        <div className="page-heading" style={{ marginBottom: "20px" }}>
          Alerts
        </div>
        <div className="ticket-sidebar-wrapper" style={{ width: "500px" }}>
          <div className="ticket-sidebar-wrapper__items-wrapper">
            {alertRenderer()}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AlertDashboard;
