import * as React from "react";
import { withRouter } from "react-router";
import { useState, useEffect, useContext } from "react";

import Loader from "../../components/helpers/Loader";
import ActiveChatContext from "../../contexts/ActiveChatContext";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import ChatRoomDetail from "../../components/chat/ChatRoomDetail";
import DashboardLayout from "../../components/layouts/DashboardLayout";

const ChatRoomDetailPage = props => {
  const [isLoading, setIsLoading] = useState(true);
  const activeChatContext = useContext(ActiveChatContext);

  useEffect(() => {
    if (props.match.params.id) {
      getChatRoom(props.match.params.id);
    }
  }, [props.match.params.id]);

  const getChatRoom = chatRoomId => {
    ticketingServiceApi
      .get(`support_chat_rooms/${chatRoomId}`)
      .then(response => {
        console.log("Chat room response", response);

        activeChatContext.setActiveChat(response.data.support_chat_room);
        setIsLoading(false);
      })
      .catch(error => {
        // TODO
      });
  };

  if (isLoading && !activeChatContext.chatRoomId) {
    return <Loader />;
  }

  return (
    <DashboardLayout>
      <ChatRoomDetail fullScreen={true} />
    </DashboardLayout>
  );
};

export default withRouter(ChatRoomDetailPage);
