import * as React from "react";
import Gravatar from "react-gravatar";
import Rating from "react-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import Loader from "../../components/helpers/Loader";

export default class RatingRequest extends React.Component {
  state = {
    ticket: null,
    isLoading: true,
    rating: null,
    ratingComment: "",
    ratingCommentLabel: "",
    showCommentBox: false
  };

  componentDidMount() {
    this.getTicketDetails();
  }

  getTicketDetails = () => {
    const props: any = this.props;
    const ticketId = props.match.params.ticket_id;

    ticketingServiceApi
      .get(`tickets/${ticketId}`)
      .then(response => {
        this.setState({
          ticket: response.data.ticket,
          isLoading: false,
          rating: response.data.ticket.rating,
          ratingComment: response.data.ticket.rating_comment,
          showCommentBox: response.data.ticket.rating ? true : false
        });
      })
      .catch(error => {
        // TODO
        // add error alert
      });
  };

  handleReviewRatingUpdate = ratingValue => {
    if (ratingValue >= 9) {
      this.setState({
        rating: ratingValue,
        showCommentBox: true,
        ratingCommentLabel:
          "That's great! Can you share a quick note on what you liked so much?"
      });
    } else if (ratingValue >= 5 && ratingValue <= 8) {
      this.setState({
        rating: ratingValue,
        showCommentBox: true,
        ratingCommentLabel:
          "Can you share a quick note on what could be improved?"
      });
    } else if (ratingValue <= 4) {
      this.setState({
        rating: ratingValue,
        showCommentBox: true,
        ratingCommentLabel:
          "We're sorry to hear about that. We're dedicated to make sure that you receive all of the support you need to succeed. Can you share a quick note on what could be improved? It will help us to improve your experience in the future"
      });
    }
  };

  handleSubmit = evt => {
    ticketingServiceApi
      .patch(`tickets/${this.state.ticket.id}`, {
        ticket: {
          rating: this.state.rating,
          rating_comment: this.state.ratingComment
        }
      })
      .then(response => {
        const thisProps: any = this.props;
        thisProps.history.push(`/tickets`);
      })
      .catch(error => {
        // TODO
      });
    evt.preventDefault();
  };

  render() {
    if (this.state.isLoading) {
      return <Loader />;
    }

    return (
      <DashboardLayout>
        <div className="request-wrapper">
          <div className="request-wrapper__card">
            <div className="request-wrapper__card__rating_wrapper">
              <Rating
                stop={10}
                onChange={val => this.handleReviewRatingUpdate(val)}
                initialRating={this.state.rating}
                emptySymbol={[
                  <span className="rating-star-empty">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-empty">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-empty">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-empty">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-empty">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-empty">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-empty">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-empty">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-empty">
                    <FontAwesomeIcon icon="medal" />
                  </span>,
                  <span className="rating-star-empty">
                    <FontAwesomeIcon icon="medal" />
                  </span>
                ]}
                fullSymbol={[
                  <span className="rating-star-full-poor">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-full-poor">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-full-poor">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-full-poor">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-full-good">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-full-good">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-full-good">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-full-good">
                    <FontAwesomeIcon icon="star" />
                  </span>,
                  <span className="rating-star-full-superior">
                    <FontAwesomeIcon icon="medal" />
                  </span>,
                  <span className="rating-star-full-superior">
                    <FontAwesomeIcon icon="medal" />
                  </span>
                ]}
              />
            </div>

            {this.state.showCommentBox ? (
              <div className="form-group" style={{ marginTop: "42px" }}>
                <label>
                  <span>{this.state.ratingCommentLabel}</span>
                </label>
                <textarea
                  className="textarea-field"
                  style={{ height: "100px" }}
                  placeholder="(Optional)"
                  onChange={e =>
                    this.setState({ ratingComment: e.target.value })
                  }
                  value={this.state.ratingComment}
                />

                <div>
                  <button onClick={this.handleSubmit} className="btn-primary">
                    SUBMIT
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </DashboardLayout>
    );
  }
}
