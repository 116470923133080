import * as React from "react";
import { useContext, useState, useEffect } from "react";
import moment from "moment";
import Gravatar from "react-gravatar";
import { css } from "glamor";
import ScrollToBottom from "react-scroll-to-bottom";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert-react";
import ReactMarkdown from "react-markdown";

import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import ActiveChatContext from "../../contexts/ActiveChatContext";
import ManageChatModal from "../chat/ManageChatModal";
import MarkdownEditorChatModal from "../chat/MarkdownEditorChatModal";
import { Link } from "react-router-dom";
import ChatInput from "./ChatInput";

interface IChatRoomDetailProps {
  fullScreen?: boolean;
}

const ChatRoomDetail = (props: IChatRoomDetailProps) => {
  const activeChatContext = useContext(ActiveChatContext);
  useEffect(() => {
    return activeChatContext.clearActiveChat;
  }, []);

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);

    return function cleanup() {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  });

  const [windowHeight, setWindowHeight] = useState(0);

  const updateWindowDimensions = () => {
    setWindowHeight(window.innerHeight);
  };
  const { permissions } = useContext(CurrentUserContext);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [markdownModalIsOpen, setMarkdownModalIsOpen] = useState(false);

  const { users, title, updated_at, thumb, token } = activeChatContext.chatRoom;

  if (!activeChatContext.showChatRoom) {
    return null;
  }

  const ROOT_CSS = css({
    height: windowHeight - 500,
    fontSize: "1.5rem",
  });

  const handleArchive = () => {
    ticketingServiceApi
      .patch(
        `support_chat_rooms/archive/${activeChatContext.chatRoom.token}`,
        {}
      )
      .then((response) => {
        setConfirmArchive(false);
        activeChatContext.clearActiveChat();
      })
      .catch((error) => {
        // TODO
        // show an error
      });
  };

  const chatMessageRenderer = () => {
    if (
      activeChatContext.liveChatMessages &&
      activeChatContext.liveChatMessages.length > 0
    ) {
      return activeChatContext.liveChatMessages.map((message, idx) => {
        return (
          <div key={idx} className="chat-message-wrapper">
            <div className="chat-message-wrapper__avatar">
              <Gravatar email={message.user_email} />
            </div>

            <div className="chat-message-wrapper__body">
              <div className="chat-message-wrapper__body__name">
                {message.user_full_name}
                {message.created_at ? (
                  <span className="chat-message-wrapper__body__name__timestamp">
                    {moment(message.created_at).format(
                      "MMMM Do YYYY h:mm:ss a"
                    )}
                  </span>
                ) : null}
              </div>

              <div
                className="markdown-container"
                style={props.fullScreen ? { maxWidth: "100vw" } : {}}
              >
                <ReactMarkdown source={message.message} />
              </div>
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const handleMarkdownModalClose = () => {
    setMarkdownModalIsOpen(false);
  };

  return (
    <div
      className="ticket-detail"
      style={{
        height: `calc(${windowHeight} - 350px)`,
      }}
    >
      <ManageChatModal
        modalIsOpen={modalIsOpen}
        handleModalClose={handleModalClose}
      />
      <MarkdownEditorChatModal
        modalIsOpen={markdownModalIsOpen}
        handleModalClose={handleMarkdownModalClose}
      />
      <div className="ticket-detail__top">
        <div className="ticket-detail__top__heading">
          <div className="ticket-detail__top__heading__left-column">
            <div className="ticket-detail__top__heading__left-column__title">
              {props.fullScreen ? (
                <Link to={`/chat`}>
                  <div style={{ marginTop: "10px" }}>{title}</div>
                </Link>
              ) : (
                <Link to={`/chat/${token}`}>
                  <FontAwesomeIcon icon="expand-arrows-alt" />
                  <div style={{ marginTop: "10px" }}>{title}</div>
                </Link>
              )}
            </div>

            {users && users.length > 2 ? (
              <div
                className="ticket-detail__top__heading__left-column__title"
                style={{ fontSize: "1.5em", color: "#333333" }}
              >
                {users.length} users in chat
              </div>
            ) : null}

            {updated_at ? (
              <div className="ticket-detail__top__heading__left-column__date">
                Last updated {moment(updated_at).fromNow()}
              </div>
            ) : null}

            {permissions.role === "admin" || permissions.role === "mentor" ? (
              <div className="chat-admin-links">
                <SweetAlert
                  show={confirmArchive}
                  title="Archive chat room"
                  text={
                    "Are you sure that you want to archive this chat? Users will not be able to access it after this action has been taken."
                  }
                  onConfirm={() => handleArchive()}
                  onEscapeKey={() => setConfirmArchive(false)}
                  onOutsideClick={() => setConfirmArchive(false)}
                  showCancelButton
                  onCancel={() => setConfirmArchive(false)}
                  confirmButtonColor="#00c274"
                />
                <a
                  className="chat-admin-links__warning"
                  onClick={() => setConfirmArchive(true)}
                >
                  <FontAwesomeIcon icon="archive" />
                  <span style={{ marginLeft: "5px" }}>Archive</span>
                </a>
                <a onClick={() => setModalIsOpen(true)}>
                  <FontAwesomeIcon icon="users-cog" />
                  <span style={{ marginLeft: "5px" }}>Manage</span>
                </a>
              </div>
            ) : null}
          </div>

          <div className="ticket-detail__top__heading__thumbnail">
            <img
              src={thumb}
              style={{
                borderRadius: "100px",
                width: "100px",
                height: "100px",
              }}
            />
          </div>
        </div>

        <ScrollToBottom className={ROOT_CSS}>
          {chatMessageRenderer()}
        </ScrollToBottom>
      </div>

      <div className="ticket-detail__bottom" style={{ width: "100%" }}>
        <ChatInput />

        <a
          style={{ fontSize: "3em" }}
          onClick={() => setMarkdownModalIsOpen(true)}
        >
          <FontAwesomeIcon icon="code" />
        </a>
      </div>
    </div>
  );
};

export default ChatRoomDetail;
