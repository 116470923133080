import * as React from "react";
import { useContext, useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import Gravatar from "react-gravatar";
import moment from "moment";
import SweetAlert from "sweetalert-react";

import CurrentUserContext from "../../../contexts/CurrentUserContext";
import TicketsContext from "../../../contexts/TicketsContext";
import ticketingServiceApi from "../../../helpers/ticketingServiceApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ICommentCardProps {
  comment: {
    id: number;
    message: string;
    full_name: string;
    updated_at: string;
    user_email: string;
    user_id: number;
    vote_count: number;
    downvoting_user_ids: number[];
    upvoting_user_ids: number[];
    top_comment: boolean;
  };
  key: number;
  ticketId: number;
}
const CommentCard = (props: ICommentCardProps) => {
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [voteCount, setVoteCount] = useState(null);
  const [downvotingUserIds, setDownvotingUserIds] = useState([]);
  const [upvotingUserIds, setUpvotingUserIds] = useState([]);

  const {
    id,
    message,
    full_name,
    updated_at,
    user_email,
    user_id,
    vote_count,
    downvoting_user_ids,
    upvoting_user_ids,
    top_comment
  } = props.comment;

  const { user, permissions } = useContext(CurrentUserContext);
  const ticketsContext = useContext(TicketsContext);

  useEffect(() => {
    if (vote_count) {
      setVoteCount(vote_count);
    } else {
      setVoteCount(0);
    }

    if (upvoting_user_ids) {
      setUpvotingUserIds(upvoting_user_ids);
    }

    if (downvoting_user_ids) {
      setDownvotingUserIds(downvoting_user_ids);
    }
  }, []);

  const handleCommentDelete = () => {
    setShowDeleteWarning(false);
    ticketingServiceApi
      .delete(`tickets/${props.ticketId}/comments/${id}`)
      .then(response => {
        if (response.data.message === "COMMENT_REMOVED") {
          ticketsContext.removeComment(id);
        } else {
          // TODO
          // Add notification
        }
      })
      .catch(error => {
        // TODO
        // show error
      });
  };

  const handleVote = (voteType: string) => {
    ticketingServiceApi
      .post(`comments/${id}/comment_votes`, {
        comment_vote: {
          vote_type: voteType
        }
      })
      .then(response => {
        if (response.data.comment) {
          setVoteCount(response.data.comment.vote_count);
          setUpvotingUserIds(response.data.comment.upvoting_user_ids);
          setDownvotingUserIds(response.data.comment.downvoting_user_ids);
        }
      })
      .catch(error => {
        // TODO
        // show error
      });
  };

  const voteLink = (voteType: string) => {
    if (voteType === "upvote") {
      if (upvotingUserIds.includes(user.id)) {
        return (
          <a onClick={() => handleVote(voteType)} style={{ fontSize: "2em" }}>
            <FontAwesomeIcon icon="caret-square-up" />
          </a>
        );
      } else {
        return (
          <a
            onClick={() => handleVote(voteType)}
            style={{ fontSize: "2em", color: "#333333" }}
          >
            <FontAwesomeIcon icon="caret-square-up" />
          </a>
        );
      }
    } else {
      if (downvotingUserIds.includes(user.id)) {
        return (
          <a
            onClick={() => handleVote(voteType)}
            style={{ fontSize: "2em", color: "#af2e2e" }}
          >
            <FontAwesomeIcon icon="caret-square-down" />
          </a>
        );
      } else {
        return (
          <a
            onClick={() => handleVote(voteType)}
            style={{ fontSize: "2em", color: "#333333" }}
          >
            <FontAwesomeIcon icon="caret-square-down" />
          </a>
        );
      }
    }
  };

  return (
    <div className="comments-container__comment-container">
      <SweetAlert
        show={showDeleteWarning}
        title="Delete comment"
        text={
          "Are you sure that you want to delete this comment? This action cannot be reversed"
        }
        onConfirm={handleCommentDelete}
        onEscapeKey={() => setShowDeleteWarning(false)}
        onOutsideClick={() => setShowDeleteWarning(false)}
        showCancelButton
        onCancel={() => setShowDeleteWarning(false)}
        confirmButtonColor="#00c274"
      />
      {top_comment ? (
        <div className={"top-comment"}>
          <FontAwesomeIcon icon="medal" /> TOP COMMENT
        </div>
      ) : null}
      <div className="markdown-container">
        <ReactMarkdown source={message} />
      </div>
      <div className="comments-container__comment-container__metadata">
        <div className="comments-container__comment-container__metadata__left">
          {permissions.role === "admin" ||
          permissions.role === "mentor" ||
          user.id === user_id ? (
            <a
              className="comments-container__comment-container__metadata__left__delete-link"
              onClick={() => setShowDeleteWarning(true)}
            >
              DELETE
            </a>
          ) : null}
        </div>

        <div className="comments-container__comment-container__metadata__right">
          <div className="comments-container__comment-container__metadata__right__text">
            <div className="comments-container__comment-container__metadata__right__avatar">
              <Gravatar email={user_email} />
            </div>
            <div className="comments-container__comment-container__metadata__right__text__name">
              {full_name}
            </div>
            <div className="comments-container__comment-container__metadata__right__text__date">
              {moment(updated_at).fromNow()}
            </div>
          </div>

          <div className="comments-container__comment-container__metadata__right__votes">
            {voteLink("upvote")}
            <div>{voteCount}</div>
            {voteLink("downvote")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentCard;
