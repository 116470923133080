import * as React from "react";

import DashboardLayout from "../../components/layouts/DashboardLayout";
import MentorDashboardTabsWrapper from "../../components/tabs/MentorDashboardTabsWrapper";

const Dashboard = () => {
  return (
    <DashboardLayout>
      <MentorDashboardTabsWrapper />
    </DashboardLayout>
  );
};

export default Dashboard;
