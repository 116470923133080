import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSave,
  faBars,
  faUserShield,
  faCaretSquareUp,
  faCaretSquareDown,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronCircleLeft,
  faChevronCircleRight,
  faExpandArrowsAlt,
  faChartLine,
  faThumbsDown,
  faInbox,
  faEnvelope,
  faReceipt,
  faMedal,
  faStar,
  faLink,
  faCode,
  faBell,
  faUserTimes,
  faArchive,
  faUsersCog,
  faCommentMedical,
  faClipboardList,
  faAt,
  faKey,
  faEdit,
  faTrash,
  faSpinner,
  faSignInAlt,
  faChalkboardTeacher,
  faCodeBranch,
  faFileCode,
  faTerminal,
  faComments,
  faAngleRight,
  faExclamationCircle,
  faSignOutAlt,
  faCaretSquareRight,
  faCalendarAlt,
  faTools,
  faUserGraduate,
  faBezierCurve,
  faCaretDown,
  faCaretUp,
  faCaretLeft,
  faCaretRight,
  faPlusSquare,
  faWindowClose,
  faCheckCircle,
  faCheckSquare,
  faArrowAltCircleRight,
  faHeading,
  faFileAlt,
  faLayerGroup,
  faEyeSlash,
  faPlayCircle,
  faClock,
  faCalendarCheck,
  faTimesCircle,
  faToggleOn,
  faToggleOff,
  faCheck,
  faCheckDouble,
  faSearch,
  faPhone,
  faTimes,
  faLevelUpAlt
} from "@fortawesome/free-solid-svg-icons";

const Icons = () => {
  return library.add(
    faLevelUpAlt,
    faPhone,
    faSave,
    faBars,
    faUserShield,
    faCaretSquareUp,
    faCaretSquareDown,
    faChevronCircleDown,
    faChevronCircleUp,
    faChevronCircleLeft,
    faChevronCircleRight,
    faExpandArrowsAlt,
    faChartLine,
    faThumbsDown,
    faInbox,
    faEnvelope,
    faReceipt,
    faMedal,
    faStar,
    faLink,
    faCode,
    faBell,
    faUserTimes,
    faArchive,
    faUsersCog,
    faCommentMedical,
    faClipboardList,
    faAt,
    faKey,
    faTrash,
    faEdit,
    faSpinner,
    faSignInAlt,
    faChalkboardTeacher,
    faCodeBranch,
    faFileCode,
    faTerminal,
    faComments,
    faAngleRight,
    faExclamationCircle,
    faSignOutAlt,
    faCaretSquareRight,
    faCalendarAlt,
    faTools,
    faUserGraduate,
    faBezierCurve,
    faCaretDown,
    faCaretUp,
    faPlusSquare,
    faWindowClose,
    faCheckCircle,
    faCheckSquare,
    faArrowAltCircleRight,
    faHeading,
    faFileAlt,
    faLayerGroup,
    faEyeSlash,
    faPlayCircle,
    faCaretLeft,
    faCaretRight,
    faClock,
    faCalendarCheck,
    faTimesCircle,
    faToggleOn,
    faToggleOff,
    faCheck,
    faCheckDouble,
    faSearch,
    faTimes
  );
};

export default Icons;
