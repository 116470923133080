import * as React from "react";
import { useState } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ticketingServiceApi from "../../../helpers/ticketingServiceApi";
import MarkdownIcon from "../../svgs/MarkdownIcon";

ReactModal.setAppElement(".app-wrapper");

const customModalStyles = {
  overlay: {
    backgroundColor: "rgba(1, 1, 1, 0.75)"
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "50vw"
  }
};

interface ITicketEscalationModalProps {
  setModalIsOpen: (boolean) => void;
  modalIsOpen: boolean;
  ticket: {
    title: string;
    id: number;
  };
}

export default (props: ITicketEscalationModalProps) => {
  const { id, title } = props.ticket;
  const [rationale, setRationale] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = evt => {
    setIsSubmitting(true);

    ticketingServiceApi
      .post(`tickets/${id}/ticket_escalations`, { rationale })
      .then(response => {
        setIsSubmitting(false);
        props.setModalIsOpen(false);
        window.location.href = "/tickets";
      })
      .catch(error => {
        // TODO
        setIsSubmitting(false);
      });

    evt.preventDefault();
  };

  const submitButton = () => {
    if (isSubmitting) {
      return (
        <button className="btn-loading" style={{ marginRight: "15px" }}>
          Submitting...
        </button>
      );
    } else if (rationale) {
      return (
        <button
          className="btn-primary"
          type="submit"
          style={{ marginRight: "15px" }}
        >
          Submit
        </button>
      );
    } else {
      return (
        <button className="btn-disabled" style={{ marginRight: "15px" }}>
          Rationale required
        </button>
      );
    }
  };

  return (
    <ReactModal
      isOpen={props.modalIsOpen}
      onRequestClose={() => props.setModalIsOpen(false)}
      style={customModalStyles}
    >
      <div className="rating-detail-modal">
        <div className="rating-detail-modal__title">
          Ticket Escalation Request for {id}, {title}
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div
            className="ticket-detail__top__heading__left-column__user"
            style={{
              fontSize: "1.3rem"
            }}
          ></div>

          <div className="ticket-detail__top__heading__thumbnail-and-rating"></div>
        </div>

        <form onSubmit={handleSubmit} className="form-wrapper">
          <div className="form-group">
            <textarea
              onChange={e => setRationale(e.target.value)}
              value={rationale}
              required
              className="textarea-field"
            />
            <label>
              Content{" "}
              <span>
                <span className="markdown-icon">
                  <MarkdownIcon />
                </span>{" "}
                Supported
              </span>
            </label>
          </div>

          <div
            className="login-btn-wrapper"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {submitButton()}

            <button
              className="btn-warning"
              style={{ marginLeft: "15px" }}
              onClick={() => props.setModalIsOpen(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </ReactModal>
  );
};
