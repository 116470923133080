import * as React from "react";
import { useContext, useState, useEffect } from "react";
import Gravatar from "react-gravatar";
import moment from "moment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Truncate from "react-truncate";

import TicketDetailModal from "./TicketDetailModal";
import ActiveTicketContent from "../../contexts/ActiveTicketContext";
import AlertContext from "../../contexts/AlertContext";

interface ITicketItem {
  ticket: {
    id: number;
    title: string;
    user_email: string;
    updated_at: string;
  };
  source: string;
  opensInModal?: boolean;
}

const TicketListItem = (props: ITicketItem) => {
  const { id, title, user_email, updated_at } = props.ticket;
  const [activeAlert, setActiveAlert] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const alertContext = useContext(AlertContext);

  useEffect(() => {
    if (alertContext.hasAlert(id, "ticket")) {
      setActiveAlert(true);
    } else {
      setActiveAlert(false);
    }
  }, [alertContext.alerts.length]);

  const activeTicketContext = useContext(ActiveTicketContent);

  const handleItemClick = () => {
    if (alertContext.hasAlert(id, "ticket")) {
      alertContext.deleteAlertFromApi(id.toString());
      setActiveAlert(false);
    }
    activeTicketContext.setActiveTicket(props.ticket);
  };

  const listItemContent = (
    <div className="ticket-sidebar-wrapper__items-wrapper__item">
      <div className="ticket-sidebar-wrapper__items-wrapper__item__left">
        <Gravatar email={user_email} />
        <div className="ticket-sidebar-wrapper__items-wrapper__item__left__title">
          <Truncate lines={1} width={300}>
            {title}
          </Truncate>
        </div>
      </div>

      <div className="ticket-sidebar-wrapper__items-wrapper__item__timestamp">
        {activeAlert ? (
          <span style={{ fontSize: "2em", color: "#af2e2e" }}>
            <FontAwesomeIcon icon="bell" />{" "}
          </span>
        ) : null}
        {moment(updated_at).fromNow()}
      </div>
    </div>
  );

  if (props.source === "TicketList") {
    return <a onClick={handleItemClick}>{listItemContent}</a>;
  }

  if (props.opensInModal) {
    return (
      <React.Fragment>
        <TicketDetailModal
          handleModalClose={() => setModalIsOpen(false)}
          modalIsOpen={modalIsOpen}
          ticket={props.ticket}
        />
        <a onClick={() => setModalIsOpen(true)}>{listItemContent}</a>
      </React.Fragment>
    );
  } else {
    return <Link to={`/ticket/${id}`}>{listItemContent}</Link>;
  }
};

export default TicketListItem;
