import React from "react";
import { Route } from "react-router-dom";

import Dashboard from "../pages/dashboard/Dashboard";
import AdminDashboard from "../pages/admin/AdminDashboard";
import SupportTierDashboard from "../pages/admin/SupportTierDashboard";
import EscalationDashboard from "../pages/admin/EscalationDashboard";
import NewTicket from "../pages/tickets/NewTicket";
import TicketDashboard from "../pages/tickets/TicketDashboard";
import TicketPage from "../pages/tickets/TicketPage";
import ChatDashboard from "../pages/chat/ChatDashboard";
import AlertDashboard from "../pages/alerts/AlertDashboard";
import RatingRequest from "../pages/ratings/RatingRequest";
import RatingDashboard from "../pages/ratings/RatingDashboard";
import PerformanceDashboard from "../pages/performance/PerformanceDashboard";
import UserDetail from "../pages/users/UserDetail";
import ChatRoomDetailPage from "../pages/chat/ChatRoomDetailPage";
import DutyEntryDashboard from "../pages/admin/DutyEntryDashboard";

export const routesForSuperAdmins = [
  <Route exact key="admin-dashboard" path="/" component={AdminDashboard} />,
  <Route
    exact
    key="duty-entries-dashboard"
    path="/duty-entries-dashboard"
    component={DutyEntryDashboard}
  />,
  <Route
    exact
    key="support-tier-dashboard"
    path="/support-tier-dashboard"
    component={SupportTierDashboard}
  />,
  <Route
    exact
    key="escalation-dashboard"
    path="/escalation-dashboard"
    component={EscalationDashboard}
  />,
  <Route exact key="new-ticket" path="/new-ticket" component={NewTicket} />,
  <Route exact key="ticket-list" path="/tickets" component={TicketDashboard} />,
  <Route exact key="ticket-page" path="/ticket/:id" component={TicketPage} />,
  <Route exact key="chat-dashboard" path="/chat" component={ChatDashboard} />,
  <Route
    exact
    key="rating-request"
    path="/rating-request/:ticket_id"
    component={RatingRequest}
  />,
  <Route
    exact
    key="alert-dashboard"
    path="/alerts"
    component={AlertDashboard}
  />,
  <Route
    exact
    key="rating-dashboard"
    path="/ratings"
    component={RatingDashboard}
  />,
  <Route
    exact
    key="performance-dashboard"
    path="/performance"
    component={PerformanceDashboard}
  />,
  <Route exact key="user-detail" path="/u/:id" component={UserDetail} />,
  <Route
    exact
    key="chat-detail"
    path="/chat/:id"
    component={ChatRoomDetailPage}
  />
];

export const routesForMentors = [
  <Route exact key="admin-dashboard" path="/" component={AdminDashboard} />,
  <Route exact key="new-ticket" path="/new-ticket" component={NewTicket} />,
  <Route exact key="ticket-list" path="/tickets" component={TicketDashboard} />,
  <Route exact key="ticket-page" path="/ticket/:id" component={TicketPage} />,
  <Route exact key="chat-dashboard" path="/chat" component={ChatDashboard} />,
  <Route
    exact
    key="rating-request"
    path="/rating-request/:ticket_id"
    component={RatingRequest}
  />,
  <Route
    exact
    key="alert-dashboard"
    path="/alerts"
    component={AlertDashboard}
  />,
  <Route
    exact
    key="rating-dashboard"
    path="/ratings"
    component={RatingDashboard}
  />,
  <Route exact key="user-detail" path="/u/:id" component={UserDetail} />,
  <Route
    exact
    key="chat-detail"
    path="/chat/:id"
    component={ChatRoomDetailPage}
  />
];

export const routesForLoggedInUsers = [
  <Route exact key="dashboard" path="/" component={Dashboard} />,
  <Route exact key="new-ticket" path="/new-ticket" component={NewTicket} />,
  <Route exact key="ticket-list" path="/tickets" component={TicketDashboard} />,
  <Route exact key="ticket-page" path="/ticket/:id" component={TicketPage} />,
  <Route exact key="chat-dashboard" path="/chat" component={ChatDashboard} />,
  <Route
    exact
    key="rating-request"
    path="/rating-request/:ticket_id"
    component={RatingRequest}
  />,
  <Route
    exact
    key="alert-dashboard"
    path="/alerts"
    component={AlertDashboard}
  />,
  <Route exact key="user-detail" path="/u/:id" component={UserDetail} />,
  <Route
    exact
    key="chat-detail"
    path="/chat/:id"
    component={ChatRoomDetailPage}
  />
];
