import * as React from "react";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import API from "../../helpers/ticketingServiceApi";
import DashboardLayout from "../../components/layouts/DashboardLayout";
import MarkdownIcon from "../../components/svgs/MarkdownIcon";
import TicketSidebar from "../../components/tickets/TicketSidebar";
import ExampleTicketModal from "../../components/tickets/ExampleTicketModal";

const NewTicket = (props) => {
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [titleIsReady, setTitleIsReady] = useState(false);
  const [guideUrl, setGuideUrl] = useState("");
  const [ticketErrorText, setTicketErrorText] = useState("");
  const [buttonState, setButtonState] = useState("ACTIVE");
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (window.location.search) {
      const cleanedPath = window.location.search.split("?guide=")[1];

      if (cleanedPath) {
        const decodedUri = decodeURIComponent(cleanedPath);
        setGuideUrl(decodedUri.substring(1));
      } else {
        setGuideUrl("");
      }
    }
  }, []);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    setButtonState("DISABLED");
    API.post(
      "tickets",
      {
        ticket: {
          title: title,
          content: content,
          status: "pending",
          guide_url: guideUrl,
        },
      },
      { withCredentials: true }
    )
      .then((response) => {
        setTitle("");
        setContent("");
        setGuideUrl("");
        props.history.push("/tickets");
      })
      .catch((error) => {
        setButtonState("ACTIVE");
        setTicketErrorText(
          "There was an error creating the ticket. Please try again, if the issue continues, please contact your instructor."
        );
      });
    event.preventDefault();
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const handleExampleTicketClick = () => {
    setModalIsOpen(true);
  };

  const characterCountdown = () => {
    return `${title.length} / 125`;
  };

  return (
    <DashboardLayout>
      <ExampleTicketModal
        modalIsOpen={modalIsOpen}
        handleModalClose={handleModalClose}
      />
      <div style={{ width: "310px", marginBottom: "15px" }}>
        <a onClick={handleExampleTicketClick} className="btn-primary">
          <FontAwesomeIcon icon="clipboard-list" />

          <span style={{ marginLeft: "10px" }}>VIEW SAMPLE TICKET</span>
        </a>
      </div>
      <div className="grid-1fr-600px">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onFocus={() => setTitleIsReady(false)}
              onBlur={() => setTitleIsReady(true)}
              maxLength={125}
              className="text-field"
              required
              placeholder="Please be very specific with your title. Briefly describe your error."
            />
            <label>
              Title<span>{characterCountdown()}</span>
            </label>
          </div>

          <div className="form-group">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              required
              className="textarea-field"
              placeholder="Please also be specific in your description. What guide are you working on and what error are you running into?"
            />
            <label>
              Content{" "}
              <span>
                <span className="markdown-icon">
                  <MarkdownIcon />
                </span>{" "}
                Supported
              </span>
            </label>
          </div>

          <div className="button-wrapper">
            {buttonState === "ACTIVE" ? (
              <button className="btn-primary" type="submit">
                Create ticket
              </button>
            ) : null}

            {buttonState === "DISABLED" ? (
              <button className="btn-loading" type="submit">
                <FontAwesomeIcon icon="spinner" spin />
              </button>
            ) : null}
          </div>

          <div className="form-error-text">{ticketErrorText}</div>
        </form>

        <TicketSidebar content={title} contentIsReady={titleIsReady} />
      </div>
    </DashboardLayout>
  );
};

export default NewTicket;
