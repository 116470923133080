import * as React from "react";
import { withRouter } from "react-router";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import Gravatar from "react-gravatar";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ActiveTicketContext from "../../contexts/ActiveTicketContext";
import TicketsContext from "../../contexts/TicketsContext";
import ActiveChatContext from "../../contexts/ActiveChatContext";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import CommentForm from "./comments/CommentForm";
import commentsRenderer from "./comments/commentsRenderer";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import TicketEscalationModal from "./escalations/TicketEscalationModal";
import EscalationBanner from "../escalations/EscalationBanner";

const TicketDetail = (props) => {
  const activeTicketContext = useContext(ActiveTicketContext);
  const activeChatContext = useContext(ActiveChatContext);
  const { user, permissions } = useContext(CurrentUserContext);
  const ticketsContext = useContext(TicketsContext);
  const [escalationModalIsOpen, setEscalationModalIsOpen] = useState(false);

  const {
    id,
    title,
    user_id,
    content,
    user_email,
    full_name,
    created_at,
    updated_at,
    status,
    ticket_assignment,
    assigned_users,
    guide_url,
    chat_room_id,
    rating,
    view_count,
    ticket_escalation,
  } = activeTicketContext.ticket;

  const comments = ticketsContext.updatingCommentsForActiveTicket;

  const handleTicketAssignmentClick = () => {
    activeTicketContext.assignTicketToUser(user);
  };

  const handleTicketClose = () => {
    activeTicketContext.clearActiveTicket();
  };

  const handleMarkTicketResolvedClick = () => {
    activeTicketContext.markTicketResolved();
  };

  const handleArchiveTicketResolvedClick = () => {
    activeTicketContext.markTicketArchived();
  };

  const redirectToChat = () => {
    if (chat_room_id) {
      ticketingServiceApi
        .get(`support_chat_rooms/${chat_room_id}`)
        .then((response) => {
          activeChatContext.setActiveChat(response.data.support_chat_room);
          props.history.push(`/chat/${chat_room_id}`);
        })
        .catch((error) => {
          // TODO
        });
    }
  };

  const createChatSession = () => {
    const idsToAdd = assigned_users.map((u) => u.user_id);

    let createdChatRoom: any = {
      support_chat_room: {
        chat_type: "direct_message",
        users: idsToAdd,
        title: `${title} chat #${id}`,
        ticket_id: id,
      },
    };

    ticketingServiceApi
      .post("support_chat_rooms", createdChatRoom)
      .then((response) => {
        activeChatContext.setActiveChat(response.data.support_chat_room);
        props.history.push(`/chat/${response.data.support_chat_room.token}`);
      })
      .catch((error) => {
        // TODO
      });
  };

  const handleReviewClick = () => {
    props.history.push(`/rating-request/${id}`);
  };

  if (!activeTicketContext.ticket.title || !user.id) {
    return null;
  }

  const startChatButton = () => {
    if (
      status !== "pending" &&
      user &&
      ticket_assignment &&
      (user_id === user.id || ticket_assignment.user_id === user.id)
    ) {
      if (chat_room_id) {
        return (
          <button className="btn-primary" onClick={redirectToChat}>
            Chat session <FontAwesomeIcon icon="comment-medical" />
          </button>
        );
      } else {
        return (
          <button className="btn-primary" onClick={createChatSession}>
            Create chat <FontAwesomeIcon icon="comment-medical" />
          </button>
        );
      }
    } else {
      return null;
    }
  };

  const reviewButton = () => {
    if (status === "resolved" && user && user_id === user.id) {
      return (
        <button className="btn-primary" onClick={handleReviewClick}>
          {rating ? "Update Review" : "Add Review"}{" "}
          <FontAwesomeIcon icon="star" />
        </button>
      );
    } else {
      return null;
    }
  };

  const markResolvedButton = () => {
    if (
      status === "in_progress" &&
      user &&
      ticket_assignment &&
      (user_id === user.id || ticket_assignment.user_id === user.id)
    ) {
      return (
        <button className="btn-primary" onClick={handleMarkTicketResolvedClick}>
          Mark resolved <FontAwesomeIcon icon="check-circle" />
        </button>
      );
    } else {
      return null;
    }
  };

  const handleReOpenTicketClick = () => {
    ticketingServiceApi
      .patch(`scoped_tickets/${id}`)
      .then((response) => {
        activeTicketContext.setActiveTicket(response.data.ticket);
      })
      .catch((error) => {
        // TODO
      });
  };

  const reOpenTicketButton = () => {
    if (status === "archived") {
      return (
        <button className="btn-primary" onClick={handleReOpenTicketClick}>
          Re-open ticket <FontAwesomeIcon icon="check-double" />
        </button>
      );
    } else {
      return null;
    }
  };

  const archiveButton = () => {
    if (status === "archived") {
      return null;
    }

    if (
      (user &&
        ticket_assignment &&
        (user_id === user.id || ticket_assignment.user_id === user.id)) ||
      permissions.role === "admin" ||
      permissions.role === "mentor"
    ) {
      return (
        <button
          className="btn-warning"
          onClick={handleArchiveTicketResolvedClick}
        >
          Mark Inactive <FontAwesomeIcon icon="archive" />
        </button>
      );
    } else {
      return null;
    }
  };

  const escalateButton = () => {
    if (
      user &&
      (permissions.role === "admin" || permissions.role === "mentor")
    ) {
      return (
        <button
          className="btn-primary"
          onClick={() => setEscalationModalIsOpen(true)}
        >
          Escalate <FontAwesomeIcon icon="level-up-alt" />
        </button>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="ticket-detail">
      <TicketEscalationModal
        setModalIsOpen={setEscalationModalIsOpen}
        modalIsOpen={escalationModalIsOpen}
        ticket={{ id, title }}
      />
      <div className="ticket-detail__top">
        {ticket_escalation &&
        (permissions.role === "admin" || permissions.role === "mentor") ? (
          <EscalationBanner escalation={ticket_escalation} />
        ) : null}
        <div className="ticket-detail__top__heading">
          <div className="ticket-detail__top__heading__left-column">
            <div className="ticket-detail__top__heading__left-column__title">
              <Link to={`/ticket/${id}`}>{title}</Link>
            </div>

            <div className="ticket-detail__top__heading__left-column__date">
              Last updated {moment(updated_at).fromNow()}
            </div>

            <div className="ticket-detail__top__heading__left-column__user">
              Published {moment(created_at).fromNow()}{" "}
              {full_name?.length > 0 ? `by ${full_name.split(" ")[0]}` : ""}
              {guide_url ? (
                <span
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <a
                    href={`https://${user.org_selected}.devcamp.com/${guide_url}`}
                    style={{
                      fontWeight: 900,
                      color: "#04965b",
                    }}
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="link" /> GUIDE
                  </a>
                </span>
              ) : null}
            </div>
          </div>

          <div className="ticket-detail__top__heading__thumbnail-and-rating">
            <div style={{ display: "flex" }}>
              {assigned_users && assigned_users.length > 0
                ? assigned_users.map((user) => (
                    <div key={user.user_email} style={{ marginLeft: "10px" }}>
                      <Gravatar email={user.user_email} />
                    </div>
                  ))
                : null}
            </div>

            <div className="ticket-detail__top__heading__thumbnail-and-rating__counter">
              <FontAwesomeIcon icon="chart-line" /> {view_count}
            </div>

            {rating ? (
              <div className="ticket-detail__top__heading__thumbnail-and-rating__rating">
                RATING: {rating} / 10
              </div>
            ) : null}
          </div>
        </div>

        <div className="markdown-container">
          <ReactMarkdown source={content} />
        </div>

        <div className="comments-container">
          <div className="comments-container__section-title">
            {comments ? comments.length : null}{" "}
            {comments
              ? comments.length === 1
                ? "Response"
                : "Responses"
              : null}
          </div>
          {commentsRenderer(comments)}
          <CommentForm />
        </div>
      </div>

      <div className="ticket-detail__bottom">
        {reOpenTicketButton()}
        {archiveButton()}
        {escalateButton()}
        {reviewButton()}
        {status === "pending" ? (
          <button className="btn-primary" onClick={handleTicketAssignmentClick}>
            Assign myself <FontAwesomeIcon icon="check-circle" />
          </button>
        ) : null}

        {startChatButton()}
        {markResolvedButton()}

        {location.pathname.match(/\/tickets/g) ? (
          <button className="btn-warning" onClick={handleTicketClose}>
            Close
            <FontAwesomeIcon icon="times-circle" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default withRouter(TicketDetail);
