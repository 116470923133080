import React, { useState, useEffect } from "react";
import { Draggable } from "react-beautiful-dnd";
import Gravatar from "react-gravatar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert-react";
import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import PhoneNumberForm from "./PhoneNumberForm";
import SupportTierOrganizationAssignmentsModal from "./SupportTierOrganizationAssignmentsModal";

interface ISupportTierItemProps {
  user: {
    id: number;
    email: string;
    phone: string;
  };
  index: number;
  handleAssignmentDeletion: (number) => void;
}
export default (props: ISupportTierItemProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [phone, setPhone] = useState(null);
  const [showOrgAssignmentModal, setShowOrgAssignmentModal] = useState(false);
  const { id, email } = props.user;

  useEffect(() => {
    if (props.user.phone) {
      setPhone(props.user.phone);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return null;
  }

  const handleDelete = () => {
    ticketingServiceApi
      .delete(`support_tier_assignments/${id}`)
      .then((response) => {
        if (response.data.msg === "ASSIGNMENT_REMOVED") {
          setConfirmDelete(false);
          props.handleAssignmentDeletion(id);
        } else {
          setConfirmDelete(false);
          alert("There was an error removing the assignment");
        }
      })
      .catch((error) => {
        setConfirmDelete(false);
        alert("There was an error removing the assignment");
      });
  };

  return (
    <Draggable draggableId={id.toString()} index={props.index}>
      {(provided) => (
        <div
          {...provided.draggableProps}
          ref={provided.innerRef}
          className="support-tier-item"
        >
          <SweetAlert
            show={confirmDelete}
            title="Removed mentor"
            text={"Are you sure that you want to remove this mentor?"}
            onConfirm={() => handleDelete()}
            onEscapeKey={() => setConfirmDelete(false)}
            onOutsideClick={() => setConfirmDelete(false)}
            showCancelButton
            onCancel={() => setConfirmDelete(false)}
            confirmButtonColor="#00c274"
          />
          <SupportTierOrganizationAssignmentsModal
            userId={id}
            handleModalClose={() => setShowOrgAssignmentModal(false)}
            modalIsOpen={showOrgAssignmentModal}
          />
          <div>
            <div className="center-vertically">
              <Gravatar email={email} />
              <span className="support-tier-item__email">
                <Link to={`/u/${id}`}>{email}</Link>
              </span>

              <span className="support-tier-item__phone">
                <PhoneNumberForm
                  userId={id}
                  phoneNumber={phone}
                  setPhone={setPhone}
                />
              </span>
            </div>
          </div>
          <div className="support-tier-item__actions">
            <div
              onClick={() => setShowOrgAssignmentModal(true)}
              className="support-tier-item__assignment"
            >
              <FontAwesomeIcon icon={"user-shield"} />
            </div>

            <div
              onClick={() => setConfirmDelete(true)}
              className="support-tier-item__trash"
            >
              <FontAwesomeIcon icon={"trash"} />
            </div>
            <div
              {...provided.dragHandleProps}
              className="support-tier-item__handle"
            >
              <FontAwesomeIcon icon={"bars"} />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};
