import * as React from "react";
import { useContext } from "react";
import Gravatar from "react-gravatar";
import moment from "moment";
import Truncate from "react-truncate";

import ActiveTicketContext from "../../contexts/ActiveTicketContext";

interface IEscalationListItemProps {
  escalation: {
    id: number;
    rationale: string;

    ticket: {
      id: number;
      title: string;
      user_email: string;
      updated_at: string;
    };
  };
}

export default (props: IEscalationListItemProps) => {
  const { setActiveTicket } = useContext(ActiveTicketContext);
  const { title, user_email, updated_at } = props.escalation.ticket;

  const listItemContent = (
    <div className="ticket-sidebar-wrapper__items-wrapper__item">
      <div className="ticket-sidebar-wrapper__items-wrapper__item__left">
        <Gravatar email={user_email} />
        <div className="ticket-sidebar-wrapper__items-wrapper__item__left__title">
          <Truncate lines={1} width={300}>
            {title}
          </Truncate>
        </div>
      </div>

      <div className="ticket-sidebar-wrapper__items-wrapper__item__timestamp">
        {moment(updated_at).fromNow()}
      </div>
    </div>
  );

  const handleClick = evt => {
    setActiveTicket(props.escalation.ticket);
    evt.preventDefault();
  };

  return <a onClick={handleClick}>{listItemContent}</a>;
};
