import * as React from "react";
import { useState, useEffect } from "react";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ticketingServiceApi from "../../helpers/ticketingServiceApi";
import Loader from "../helpers/Loader";
import selectFieldDataFormatter from "../../helpers/selectFieldDataFormatter";

interface ISupportTierAssignmentFormProps {
  handleAssignments: (string, []) => void;
}

export default (props: ISupportTierAssignmentFormProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTier, setSelectedTier] = useState({ label: "", value: "" });
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [autoCompleteUsers, setAutoCompleteUsers] = useState([]);
  const [supportTierOptions, setSupportTierOptions] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganizations, setSelectedOrganizations] = useState([]);
  const [errorText, setErrorText] = useState(null);

  const setBaseState = () => {
    setSelectedTier({ label: "", value: "" });
    setUsersToAdd([]);
    setSearchValue("");
    setAutoCompleteUsers([]);
    setErrorText(null);
    setOrganizations([]);
    setSelectedOrganizations([]);
  };

  useEffect(() => {
    getSupportTierOptions();
    getOrganizations();
  }, []);

  const getOrganizations = () => {
    ticketingServiceApi
      .get("organizations")
      .then((response) => {
        setOrganizations(
          selectFieldDataFormatter({
            data: response.data.organizations,
            labelName: "name",
          })
        );
      })
      .catch((error) => {
        console.log("ERROR GETTING ORGS", error);
      });
  };

  const getSupportTierOptions = () => {
    ticketingServiceApi
      .get("support_tiers")
      .then((response) => {
        setSupportTierOptions(
          selectFieldDataFormatter({
            data: response.data.support_tiers,
            labelName: "name",
          })
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("An error occurred getting the support tiers", error);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const idsToAdd = usersToAdd.map((u) => u.value.toString());
    const orgsToAdd = selectedOrganizations.map((u) => u.value.toString());

    let supportTierAssignments: any = {
      support_tier_assignments: {
        support_tier_id: selectedTier.value,
        user_ids: idsToAdd,
        organization_ids: orgsToAdd,
      },
    };

    ticketingServiceApi
      .post("support_tier_assignments", supportTierAssignments)
      .then((response) => {
        if (response.data.support_tier_assignments) {
          const formattedUsers = usersToAdd.map((u) => ({
            id: u.value,
            email: u.label,
          }));

          props.handleAssignments(selectedTier.label, formattedUsers);
          setBaseState();
        } else {
          setErrorText("An error occurred, please try again");
        }
      })
      .catch((error) => {
        setErrorText("An error occurred, please try again");
      });
  };

  const handleQuery = (inputValue) => {
    setSearchValue(inputValue);

    if (inputValue && inputValue.length > 2) {
      ticketingServiceApi
        .get(`search-users?query=${inputValue}`)
        .then((response) => {
          const users = response.data.users.map((user) => {
            return {
              label: `${user.first_name} ${user.last_name} ${user.email}`,
              value: user.id,
            };
          });

          if (users && users.length > 0) {
            setAutoCompleteUsers(users);
          }
        })
        .catch((error) => {
          // TODO
          // show error
        });
    }
  };

  const handleChange = (value) => {
    usersToAdd.filter((user) => value.includes(user));
    setUsersToAdd(value);
  };

  const handleSelectedOrganizationChange = (value) => {
    selectedOrganizations.filter((org) => value.includes(org));
    setSelectedOrganizations(value);
  };

  if (isLoading) {
    return <Loader containedLoader={true} />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="form-wrapper">
        {errorText ? (
          <div className="form-error">
            <FontAwesomeIcon icon="exclamation-circle" />
            {errorText}
          </div>
        ) : null}

        <div className="form-group" style={{ marginBottom: "20px" }}>
          <Select
            value={selectedTier}
            onChange={(val) => setSelectedTier(val)}
            options={supportTierOptions}
            placeholder="Select the tier to assign them to"
            classNamePrefix="search-select"
            isClearable={true}
          />
          <label className="spacer10">Support Tier</label>
        </div>

        <div className="form-group" style={{ marginBottom: "20px" }}>
          <Select
            isMulti={true}
            value={selectedOrganizations}
            onChange={handleSelectedOrganizationChange}
            options={organizations}
            placeholder="Select the organization(s) to assign them to"
            classNamePrefix="search-select"
            isClearable={true}
          />
          <label className="spacer10">Organizations</label>
        </div>

        <div className="form-group" style={{ marginBottom: "20px" }}>
          <Select
            isMulti={true}
            inputValue={searchValue}
            onInputChange={(val) => handleQuery(val)}
            value={usersToAdd}
            onChange={handleChange}
            options={autoCompleteUsers}
            placeholder="Search via email or name"
            classNamePrefix="search-select"
            isClearable={true}
          />
          <label className="spacer10">Admins to assign</label>
        </div>

        <div
          className="login-btn-wrapper"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button className="btn-primary" type="submit">
            Assign User
          </button>
        </div>
      </form>
    </div>
  );
};
